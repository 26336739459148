import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles.css';

// import required modules
import { Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom/dist';


const Slider = () => {

    // const audioRef = useRef(null);

    // const [isPlaying, setIsPlaying] = useState(false);
    // const toggleAudio = () => {
    //     if (audioRef.current) {
    //         if (isPlaying) {
    //             audioRef.current.pause();
    //         } else {
    //             audioRef.current.play();
    //         }
    //         setIsPlaying(!isPlaying);
    //     }
    // };

    // useEffect(() => {
    //     // Automatically play audio when the component mounts
    //     toggleAudio();

    //     // Clean up the audio element when the component is unmounted
    //     return () => {
    //         if (audioRef.current) {
    //             audioRef.current.pause();
    //             audioRef.current.currentTime = 0;
    //         }
    //     };
    // }, []);


    return (
        <>

            <section id="slider-area">
                <div className="slider-active-wrap owl-carousel  text-center text-md-start">

                    <div className="single-slide-wrap slider_blue_lines">
                        <Swiper
                            spaceBetween={5}
                            // autoplay={{
                            //     delay: 2000,
                            //     disableOnInteraction: false,
                            // }}
                            rewind={true}
                            pagination={{
                                clickable: true,
                            }}
                            effect={'fade'}
                            modules={[Autoplay]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className="slider_img_one" style={{ backgroundImage: 'url(assets/images/s4.jpg)' }}>
                                    <div className="slider_layer">
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slider_img_two" style={{ backgroundImage: 'url(assets/images/s4.jpg)' }}>
                                    <div className="slider_layer">
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slider_img_two" style={{ backgroundImage: 'url(assets/images/s4.jpg)' }}>
                                    <div className="slider_layer">
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>

                        <div className="cut_line">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#d2d2fb" fillOpacity="1" d="M0,160L720,256L1440,128L1440,320L720,320L0,320Z"></path></svg>
                        </div>
                        <div className="cut_line_two">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#9999f7" fillOpacity="1" d="M0,160L720,256L1440,128L1440,320L720,320L0,320Z"></path></svg>
                        </div>
                        <div className="cut_line_three">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#7272f3" fillOpacity="1" d="M0,160L720,256L1440,128L1440,320L720,320L0,320Z"></path></svg>
                        </div>
                    </div>
                </div>
                <div className="social-networks-icon">
                    {/* <div className="audio_Play">
                        <button onClick={toggleAudio}>
                            {isPlaying ? <FaRegPauseCircle /> : <FaPlayCircle />}
                        </button>
                        <audio ref={audioRef} src="assets/images/osa_anthem.mp4" />

                    </div> */}

                    <ul>
                        <li>
                            <Link to={''}>
                                <i className="fa-brands fa-facebook-f"></i> <span>Facebook</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="https://wa.link/oxd6wz" target="_blank">
                                <i className="fab fa-whatsapp" /> <span>WhatsApp</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={''}>
                                <i className="fab fa-instagram" /> <span>Instagram</span>
                            </Link>
                        </li>
                    </ul>
                </div>

            </section>

        </>
    )
}

export default Slider
