import Allroutes from "./Allroutes/Allroutes";
import React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <ToastContainer />
      <Allroutes />
    </>
  );
}

export default App;
