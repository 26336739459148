import React from 'react'
import PageHeader from '../Components/PageHeader'

const PresidentMsg = () => {
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="President's Message" />

          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="about_main_container">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
                      <div className="card">
                        <div className="president_img bluebgimg_here">
                          <center><img src="assets/images/Haris_Siddiqui.png" alt="" />
                          </center>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                      <div className="president_msg">
                        <h4 className='president_msg_here'>
                          Dear Esteemed Alumni</h4>
                        <p><i className="fa fa-quote-left" aria-hidden="true"></i>
                          &emsp; As the President of our cherished Old Students' Association, it brings me great joy to welcome you to our digital hub, a virtual home that celebrates our collective achievements and enduring connections. This platform is a testament to the strength of our alumni network—a space designed for us to reconnect, share stories, and foster the bonds that tie us to our alma mater. Your presence here adds depth to our community and enriches the tapestry of experiences we've woven together.</p>
                        <p> I invite you to explore the array of resources, events and opportunities crafted to nourish our relationships and support your ongoing journey. Be it professional networking, mentorship, or simply reliving those cherished moments, this platform serves as a bridge to our shared past and future endeavours.
                          Your engagement is pivotal in shaping the legacy we continue to build. Your stories, insights, and contributions will further solidify our collective impact on the world beyond these digital walls.
                        </p>
                        <p>Thank you for being an integral part of our alumni family. Here's to rekindling old connections, forging new ones, and embarking on this exciting journey together.  <i className="fa fa-quote-right" aria-hidden="true"></i></p>

                        <h5 className=' mt-5 text-end President_name'>Warm regards,</h5>
                        <h5 className=' text-end President_name '>Prof. (Dr.) Mohammed Haris Siddiqui</h5>
                        <h6 className=' text-end President_name '>President, OSA-SMCIC</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PresidentMsg
