import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    upper();
  }, [location.pathname]);
  const upper = () => {
    window.scrollTo(0, 0);
  }
  // const [currentYear, setCurrentYear] = useState();

  return (
    <>
      <footer id="footer-area">
        <div className="footer-widget section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                <div className="school_name_here">
                  <div className="school_logo">
                    <center>
                      <Link to="/"><img src="/assets/images/l2.png" alt="Logo" onClick={upper} />
                      </Link>
                    </center>
                  </div>
                  <div className="school_name">
                    <h1 ><Link to="/" onClick={upper} ><span>St Mary's Convent Inter College</span></Link></h1>
                    <h2><Link to="/" onClick={upper} ><span>Old Students' Association</span></Link></h2>
                    <h3><Link to="/" onClick={upper} ><span>Lucknow</span></Link></h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                <div className="usefull_footerlink">
                  <ul className='usefull_links'>
                    <li>
                      <Link to="/About_School">About School</Link>
                    </li>
                    <li>
                      <Link to="/AdvisoryMembers">Advisory Members</Link>
                    </li>
                    <li>
                      <Link to="/Register">Registration</Link>
                    </li>
                    {/* <li>
                      <Link to="/Login">Login</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/Login">Login</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/AlumniDirectory">Alumni Directory</Link>
                    </li> */}
                    <li>
                      <Link to="/OurAchievers">Our Achievers</Link>
                    </li>
                    <li>
                      <Link to="/SchoolAnthem">School Anthem</Link>
                    </li>
                    <li>
                      <Link to="/Principal_Till_now">Our Principals</Link>
                    </li>
                    <li>
                      <Link to="/Suggestion">Suggestion</Link>
                    </li>
                    <li>
                      <Link to="/ContactUs">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="footer-bottom-text">
                  <p>©  Copyright  {new Date().getFullYear()}- OSA SMCIC | Powered By - <Link to="https://onebigbit.com/" target='_blank'>OneBigBit Technologies Private Limited</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </>
  )
}

export default Footer
