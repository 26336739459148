import React from 'react'
import { Link } from 'react-router-dom'

const HomeAbout = () => {
    return (
        <>
            <section className='after_slider_content'>
                <div className="container">
                    <div className="row pl-0 pr-0">
                        <div className="col-lg-1 col-md-1 col-sm-1 col-1 pl-0 pr-0">
                            <div className="line_no_one"></div>
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-10 pr-0 ">
                            <div className="main_msg_container">
                                <h1>About OSA</h1>
                                <div className="principal_msg_content">
                                    <p>Our OLD STUDENTS' ASSOCIATION was started in 2002 with the support of that time Principal, Sr.Teresina, by forming a Team of Committee Members headed by Gurmeet Singh Kalra (1979) as the Founder President whose tenure lasted for two decades upto 2022.
                                        The formation took place in continuation to the Margarita Group, initiated by our first Principal of St. Mary's School, Sr. Margarita (1964-1972) which consisted Members only from her duration of Headship....
                                        <Link to="/AboutosaContent" className='ReadMoreOSA text-primary'>Read More</Link> </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-1 col-1 pl-0 pr-0">
                            <div className="line_no_two"></div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeAbout
