import React from 'react'
import PageHeader from '../Components/PageHeader'
import Alldata from '../alumni'

const AlumniDirectory = () => {
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="Alumni Directory" />
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12" >
                <div className="card Alumni_Directory_main_container ">
                  <div className="card scrollableTable ">
                    <table style={{ borderTop: "none" }} className="table table-responsive table table-striped table-hover table-bordered table-condensed usersTables pt-0">
                      <thead>
                        <tr>
                          <th scope="col">S.No.</th>
                          <th scope="col">Name</th>
                          <th scope="col">Mobile Number</th>
                          <th scope="col">Email Id</th>
                          <th scope="col" >Year of passing out</th>
                          <th scope="col">House</th>
                          <th scope="col">Profession</th>
                          <th scope="col">Organization Name</th>
                          <th scope="col">Current City</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          Alldata.map((data, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{data.id}</th>
                                <td>{data.name}</td>
                                <td></td>
                                <td></td>
                                <td>{data.year}</td>
                                <td>{data.House}</td>
                                <td>{data.Profession}</td>
                                <td>{data.organization}</td>
                                <td>{data.city}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default AlumniDirectory
