import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "../Components/Footer";
import Home from "../Pages/Home";
import SidebarMenu from '../Components/SidebarMenu';
import PresidentMsg from '../Pages/PresidentMsg'
import FounderPresidentMsg from '../Pages/FounderPresidentMsg'
import SecretaryMsg from '../Pages/SecretaryMsg'
import OfficeBearers from '../Pages/OfficeBearers'
import AdvisoryMembers from '../Pages/AdvisoryMembers'
import Register from '../Pages/Register'
import Login from '../Pages/Login'
import OurAchievers from '../Pages/OurAchievers'
import AlumniDirectory from '../Pages/AlumniDirectory'
import SchoolAnthem from '../Pages/SchoolAnthem'
import VicePresidentmsg from '../Pages/VicePresidentmsg'
import Navbar from '../Components/Navbar';
import ExSecretaryMsg from '../Pages/ExSecretaryMsg';
import ContactUs from '../Pages/ContactUs';
import Suggestion from '../Pages/Suggestion';
import PrincipalMsg from '../Pages/PrincipalMsg';
import PrincipalTillNow from '../Pages/PrincipalTillNow';
import AboutSchool from '../Pages/AboutSchool';
import JointSecretary from '../Pages/JointSecretary';
import Blog from '../Pages/Blog';
import Gallery from '../Pages/Gallery';
import GalleryTwo from '../Pages/GalleryTwo';
import AboutosaContent from '../Pages/AboutosaContent';
import BlogDetails from '../Pages/BlogDetails';
import AllNewJoiners from '../Pages/AllNewJoiners';
import Error404Page from '../Pages/Error404Page';

const Allroutes = () => {
    return (
        <>
            <BrowserRouter>
                <Navbar />
                <SidebarMenu />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/About_School" element={<AboutSchool />} />
                    <Route path="/AboutosaContent" element={<AboutosaContent />} />
                    <Route path="/AllNewJoiners" element={<AllNewJoiners />} />
                    <Route path="/PresidentMsg" element={<PresidentMsg />} />
                    <Route path="/Founder_PresidentMsg" element={<FounderPresidentMsg />} />
                    <Route path="/SecretaryMsg" element={<SecretaryMsg />} />
                    <Route path="/Joint_SecretaryMsg" element={<JointSecretary />} />
                    <Route path="/Blog" element={<Blog />} />
                    <Route path="/BlogDetails/:id" element={<BlogDetails />} />
                    <Route path='/Gallery' element={<Gallery />} />
                    <Route path='/GalleryImg/:id' element={<GalleryTwo />} />
                    <Route path="/Principal_Msg" element={<PrincipalMsg />} />
                    <Route path="/Principal_Till_now" element={<PrincipalTillNow />} />
                    <Route path="/Founder_SecretaryMsg" element={<ExSecretaryMsg />} />
                    <Route path="/OfficeBearers" element={<OfficeBearers />} />
                    <Route path="/ContactUs" element={<ContactUs />} />
                    <Route path="/AdvisoryMembers" element={<AdvisoryMembers />} />
                    <Route path="/VicePresidentmsg" element={<VicePresidentmsg />} />
                    <Route path="/Register" element={<Register />} />
                    <Route path="/Login" element={<Login />} />
                    <Route path="/OurAchievers" element={<OurAchievers />} />
                    <Route path="/AlumniDirectory" element={<AlumniDirectory />} />
                    <Route path="/SchoolAnthem" element={<SchoolAnthem />} />
                    <Route path="/Suggestion" element={<Suggestion />} />
                    <Route path="/*" element={<Error404Page />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </>
    )
}

export default Allroutes
