import React, { useEffect, useState } from 'react'
import PageHeader from '../Components/PageHeader'
import { Card, Row, Col, Container } from "reactstrap";
import ValidationRegister from "../Validation/RegistrationForm"
import { toast } from 'react-toastify';

const Register = () => {
  const [error, setError] = useState({});
  const [records, setRecords] = useState([]);

  const [batchyear, setBatchyear] = useState();
  // const [passportImg, setPassportImg] = useState(null);
  // const [classTenImg, setClassTenImg] = useState(null);
  useEffect(() => {
    getyear();
  }, []);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [inputdata, setInputdata] = useState({
    fname: "",
    mname: "",
    lname: "",
    dob: "",
    maritalstatus: "",
    marriedname: "",
    profession: "",
    organization: "",
    designation: "",
    mobile: "",
    whatsappNo: "",
    email: "",
    country: "",
    city: "",
    batch: "",
    leavingYear: "",
    lastclass: "",
    gender: "",
    graduation: "",
    graduationcollege: "",
    postgraduation: "",
    college_postgraduation: "",
    DLEd: "",
    DLEd_college: "",
    BEd: "",
    BEd_college: "",
    image: "",
    class_Ten: "",
    terms_condition: "",
    personal_info: "",
  });

  const handleinputs = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const newvalue = { ...inputdata, [name]: value };
    setInputdata(newvalue);
  }
  // console.log(inputdata)
  const Registerform = async (e) => {
    e.preventDefault();
    setError(ValidationRegister(inputdata));

    try {
      const payload = {
        first_name: inputdata.fname,
        middle_name: inputdata.mname,
        last_name: inputdata.lname,
        dob: inputdata.dob,
        marital_status: inputdata.maritalstatus,
        married_name: inputdata.marriedname,
        profession: inputdata.profession,
        organization: inputdata.organization,
        designation: inputdata.designation,
        mobile_number: inputdata.mobile,
        whatsapp_number: inputdata.whatsappNo,
        email: inputdata.email,
        country: inputdata.country,
        city: inputdata.city,
        batch: inputdata.batch,
        yol: inputdata.leavingYear,
        last_class: inputdata.lastclass,
        gender: inputdata.gender,
        graduation: {
          graduation_year: inputdata.graduation,
          college: inputdata.graduationcollege
        },
        post_graduation: {
          graduation_year: inputdata.postgraduation,
          college: inputdata.college_postgraduation
        },
        ttc_dled: {
          year: inputdata.DLEd,
          college: inputdata.DLEd_college
        },
        bEd: {
          year: inputdata.BEd,
          college: inputdata.BEd_college
        },
        passport_size_photo_url: inputdata.image,
        doc_url: inputdata.class_Ten,
        terms_and_service: checked1,
        share_personal_info: checked2,
      }
      console.log(payload, "PL");
      // return;
      const base_url = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${base_url}/registrations/insert`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      // console.log(response, 'response');

      if (response.status === 200) {
        toast.success('You are register successfully!', { autoClose: 2000 })
        console.log(response.statusText, 'Form submitted successfully');
      } else {
        toast.error('Something went wrong!', { autoClose: 2000 })
        console.error('Failed to submit form');
      }
      setSubmitted(true)
    } catch (error) {
      console.error('Error submitting form:', error);
    }
    setChecked1(false);
    setChecked2(false);
    const newrecords = { ...inputdata, id: new Date().getTime().toString() }
    setRecords([...records, newrecords])
    setInputdata({
      fname: "",
      mname: "",
      lname: "",
      dob: "",
      maritalstatus: "",
      marriedname: "",
      profession: "",
      organization: "",
      designation: "",
      mobile: "",
      whatsappNo: "",
      email: "",
      country: "",
      city: "",
      batch: "",
      leavingYear: "",
      lastclass: "",
      gender: "",
      graduation: "",
      graduationcollege: "",
      postgraduation: "",
      college_postgraduation: "",
      DLEd: "",
      DLEd_college: "",
      BEd: "",
      BEd_college: "",
      image: "",
      class_Ten: "",
      terms_condition: false,
      personal_info: false,
    });
  };


  const getyear = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 1978;
    const years = [];
    for (let year = currentYear; year >= startYear; year--) {
      years.push(year);
    }
    setBatchyear(years)
  }
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="Registration" />
          <Container>
            <Row>
              <Col lg={12} md={12}>
                <div className="registerform_main_container ">
                  <Card>
                    <Row>
                      <Col md={12}>
                        <Card className='register_card_here'>
                          <form className='register_form' method='POST' onSubmit={Registerform}>

                            <div className="row from-group ">
                              <div className="col-md-6 form-input ">
                                <label htmlFor="firstname" className="form-label"> First Name  <span className='text-danger'>*</span></label>
                                <input type="text" required className="form-control" name="fname" value={inputdata.fname} onChange={handleinputs} id="firstname" placeholder='First Name (As per School Record)' />
                                {error.fname && <p className='error'>{error.fname}</p>}

                              </div>
                              <div className="col-md-6 form-input ">
                                <label htmlFor="middlename" className="form-label"> Middle Name  </label>
                                <input type="text" className="form-control" name="mname" value={inputdata.mname} onChange={handleinputs} id="middlename" placeholder='Middle Name (As per School Record)' />
                              </div>
                            </div>

                            <div className="row from-group ">
                              <div className="col-md-6 form-input ">
                                <label htmlFor="lastname" className="form-label"> Last Name  <span className='text-danger'>*</span></label>
                                <input type="text" className="form-control" name="lname" required value={inputdata.lname} onChange={handleinputs} id="lastname" placeholder='Last Name (As per School Record)' />
                                {error.lname && <p className='error'>{error.lname}</p>}

                              </div>
                              <div className="col-md-6 form-input ">
                                <label htmlFor="dob" className="form-label"> Date Of Birth <span className='text-danger'>*</span> </label>
                                <input type="date" className="form-control" name="dob" required value={inputdata.dob} onChange={handleinputs} id="dob" />
                                {error.dob && <p className='error'>{error.dob}</p>}
                              </div>
                            </div>
                            <div className="row from-group ">
                              <div className="col-md-6 form-input ">
                                <label htmlFor="MaritalStatus" className="form-label"> Marital Status </label>
                                <select name="maritalstatus" id="maritalstatus" value={inputdata.maritalstatus} onChange={handleinputs} className=''>
                                  <option selected="selected" value="0">--Select--</option>
                                  <option value="single">Single</option>
                                  <option value="married">Married</option>
                                  <option value="widowed">Widowed</option>
                                  <option value="divorced">Divorced</option>
                                </select>
                              </div>
                              <div className="col-md-6 form-input ">
                                <label htmlFor="middlename" className="form-label"> Married Name </label>
                                <input type="text" className="form-control" name="marriedname" value={inputdata.marriedname} onChange={handleinputs} id="middlename" placeholder='Married Name' />
                              </div>
                            </div>
                            <div className="row from-group ">
                              <div className="col-md-6 form-input ">
                                <label htmlFor="Profession" className="form-label"> Profession </label>
                                <input type="text" className="form-control" name="profession" value={inputdata.profession} onChange={handleinputs} id="Profession" placeholder='Profession' />
                              </div>
                              <div className="col-md-6 form-input ">
                                <label htmlFor="Organization" className="form-label"> Organization </label>
                                <input type="text" className="form-control" name="organization" value={inputdata.organization} onChange={handleinputs} id="Organization" placeholder='Organization' />
                              </div>
                            </div>
                            <div className="row from-group ">
                              <div className="col-md-6 form-input ">
                                <label htmlFor="Designation" className="form-label"> Designation </label>
                                <input type="text" className="form-control" name="designation" value={inputdata.designation} onChange={handleinputs} id="Designation" placeholder='Designation' />
                              </div>
                              <div className="col-md-6 form-input ">
                                <label htmlFor="mobile" className="form-label"> Mobile No. <span className='text-danger'>*</span> </label>
                                <input type="number" className="form-control" required name="mobile" value={inputdata.mobile} onChange={handleinputs} id="mobile" placeholder='Mobile Number' />
                                {/* {error.mobile && <p className='error'>{error.mobile}</p>} */}

                              </div>
                            </div>
                            <div className="row from-group ">
                              <div className="col-md-6 form-input ">
                                <label htmlFor="whatsappNo" className="form-label"> WhatsApp No. <span className='text-danger'>*</span> </label>
                                <input type="number" className="form-control" required name="whatsappNo" value={inputdata.whatsappNo} onChange={handleinputs} id="whatsappNo" placeholder='WhatsApp Number' />
                                {/* {error.whatsappNo && <p className='error'>{error.whatsappNo}</p>} */}

                              </div>
                              <div className="col-md-6 form-input ">
                                <label htmlFor="email" className="form-label"> Email ID <span className='text-danger'>*</span> </label>
                                <input type="email" className="form-control" required name="email" value={inputdata.email} onChange={handleinputs} id="email" placeholder='Email ID' />
                                {/* {error.email && <p className='error'>{error.email}</p>} */}

                              </div>
                            </div>
                            <div className="row from-group ">
                              <div className="col-md-6 form-input ">
                                <label htmlFor="Country" className="form-label"> Current Country <span className='text-danger'>*</span> </label>
                                <select id="Country" className="form-control" required name="country" value={inputdata.country} onChange={handleinputs}   >
                                  <option selected>Select Your Country</option>
                                  <option value="1">Afganistan</option>
                                  <option value="2">Albania</option>
                                  <option value="3">Algeria</option>
                                  <option value="4">Andorra</option>
                                  <option value="5">Angola</option>
                                  <option value="6">Antigua &amp; Barbuda</option>
                                  <option value="7">Argentina</option>
                                  <option value="8">Armenia</option>
                                  <option value="9">Australia</option>
                                  <option value="10">Austria</option>
                                  <option value="11">Azerbaijan</option>
                                  <option value="12">Bahamas</option>
                                  <option value="13">Bahrain</option>
                                  <option value="14">Bangladesh</option>
                                  <option value="15">Barbados</option>
                                  <option value="16">Belarus</option>
                                  <option value="17">Belgium</option>
                                  <option value="18">Belize</option>
                                  <option value="19">Benin</option>
                                  <option value="20">Bhutan</option>
                                  <option value="21">Bolivia</option>
                                  <option value="22">Bosnia &amp; Herzegovina</option>
                                  <option value="23">Botswana</option>
                                  <option value="24">Brazil</option>
                                  <option value="25">Brunei Darussalam</option>
                                  <option value="26">Bulgaria</option>
                                  <option value="27">Burkina Faso</option>
                                  <option value="28">Burma</option>
                                  <option value="29">Burundi</option>
                                  <option value="30">Cambodia</option>
                                  <option value="31">Cameroon</option>
                                  <option value="32">Canada</option>
                                  <option value="33">Cape Verde</option>
                                  <option value="34">Central African Republic</option>
                                  <option value="35">Chad</option>
                                  <option value="36">Chile</option>
                                  <option value="37">China</option>
                                  <option value="38">Colombia</option>
                                  <option value="39">Comoros</option>
                                  <option value="40">Congo</option>
                                  <option value="41">Congo,Democratic Republic of the</option>
                                  <option value="42">Costa Rica</option>
                                  <option value="43">Cote d'lvoire</option>
                                  <option value="44">Croatia</option>
                                  <option value="45">Cuba</option>
                                  <option value="46">Cyprus</option>
                                  <option value="47">Czech Republic</option>
                                  <option value="48">Denmark</option>
                                  <option value="49">Djibouti</option>
                                  <option value="50">Dominica</option>
                                  <option value="51">Dominican Republic</option>
                                  <option value="52">Ecuador</option>
                                  <option value="53">East Timor</option>
                                  <option value="54">Egypt</option>
                                  <option value="55">El Salvador</option>
                                  <option value="56">England</option>
                                  <option value="57">Equatorial Guinea</option>
                                  <option value="58">Eritrea</option>
                                  <option value="59">Estonia</option>
                                  <option value="60">Ethiopia</option>
                                  <option value="61">Fiji</option>
                                  <option value="62">Finland</option>
                                  <option value="63">France</option>
                                  <option value="64">Gabon</option>
                                  <option value="65">Gambia,The</option>
                                  <option value="66">Georgia</option>
                                  <option value="67">Germany</option>
                                  <option value="68">Ghana</option>
                                  <option value="69">Great Britain</option>
                                  <option value="70">Greece</option>
                                  <option value="71">Grenada</option>
                                  <option value="72">Guatemala</option>
                                  <option value="73">Guinea</option>
                                  <option value="74">Guinea-Bissau</option>
                                  <option value="75">Guyana</option>
                                  <option value="76">Haiti</option>
                                  <option value="77">Honduras</option>
                                  <option value="78">Hungary</option>
                                  <option value="79">Iceland</option>
                                  <option value="80">India</option>
                                  <option value="81">Indonesia</option>
                                  <option value="82">Iran</option>
                                  <option value="83">Iraq</option>
                                  <option value="84">Ireland</option>
                                  <option value="85">Israel</option>
                                  <option value="86">Italy</option>
                                  <option value="87">Jamacia</option>
                                  <option value="88">Japan</option>
                                  <option value="89">Jordan</option>
                                  <option value="90">Kazakhstan</option>
                                  <option value="91">Kenya</option>
                                  <option value="92">Kiribati</option>
                                  <option value="93">Korea,North</option>
                                  <option value="94">Korea,South</option>
                                  <option value="95">Kosovo</option>
                                  <option value="96">Kuwait</option>
                                  <option value="97">Kyrgyzstan</option>
                                  <option value="98">Laos</option>
                                  <option value="99">Latvia</option>
                                  <option value="100">Lebanon</option>
                                  <option value="101">Lesotho</option>
                                  <option value="102">Liberia</option>
                                  <option value="103">Libya</option>
                                  <option value="104">Liechtenstein</option>
                                  <option value="105">Lithuania</option>
                                  <option value="106">Luxembourg</option>
                                  <option value="107">Macedonia</option>
                                  <option value="108">Madagascar</option>
                                  <option value="109">Malawi</option>
                                  <option value="110">Malaysia</option>
                                  <option value="111">maldives</option>
                                  <option value="112">Mali</option>
                                  <option value="113">Malta</option>
                                  <option value="114">Marshall Islands</option>
                                  <option value="115">Mauritania</option>
                                  <option value="116">Mauritius</option>
                                  <option value="117">Mexico</option>
                                  <option value="118">Micronesia</option>
                                  <option value="119">Moldova</option>
                                  <option value="120">Monaco</option>
                                  <option value="121">Mongolia</option>
                                  <option value="122">Montenegro</option>
                                  <option value="123">Morocco</option>
                                  <option value="124">Mozambique</option>
                                  <option value="125">Myanmar</option>
                                  <option value="126">Namibia</option>
                                  <option value="127">Nauru</option>
                                  <option value="128">Nepal</option>
                                  <option value="129">Netherlands</option>
                                  <option value="130">New Zealand</option>
                                  <option value="131">Nicaragua</option>
                                  <option value="132">Niger</option>
                                  <option value="133">Nigeria</option>
                                  <option value="134">Norway</option>
                                  <option value="135">Northern Ireland</option>
                                  <option value="136">Oman</option>
                                  <option value="137">Pakistan</option>
                                  <option value="138">Palau</option>
                                  <option value="139">Palestinian State</option>
                                  <option value="140">Panama</option>
                                  <option value="141">Papua New Guinea</option>
                                  <option value="142">Paraguay</option>
                                  <option value="143">Peru</option>
                                  <option value="144">Philippines</option>
                                  <option value="145">Poland</option>
                                  <option value="146">Portugal</option>
                                  <option value="147">Qatar</option>
                                  <option value="148">Romania</option>
                                  <option value="149">Russia</option>
                                  <option value="150">Rwanda</option>
                                  <option value="151">St.Kitts &amp; Nevis</option>
                                  <option value="152">St.Lucia</option>
                                  <option value="153">St.Vincent &amp; The Grenadines</option>
                                  <option value="154">Samoa</option>
                                  <option value="155">San Marino</option>
                                  <option value="156">Sao Tome &amp; Principe</option>
                                  <option value="157">Saudi Arabia</option>
                                  <option value="158">Scotland</option>
                                  <option value="159">Senegal</option>
                                  <option value="160">Serbia</option>
                                  <option value="161">Seychelles</option>
                                  <option value="162">Sierra Leone</option>
                                  <option value="163">Singapore</option>
                                  <option value="164">Slovenia</option>
                                  <option value="165">Solomon Islands</option>
                                  <option value="166">Somalia</option>
                                  <option value="167">South Africa</option>
                                  <option value="168">Spain</option>
                                  <option value="169">Sri Lanka</option>
                                  <option value="170">Sudan</option>
                                  <option value="171">South Sudan</option>
                                  <option value="172">Suriname</option>
                                  <option value="173">Swaziland</option>
                                  <option value="174">Sweden</option>
                                  <option value="175">Switzerland</option>
                                  <option value="176">Syria</option>
                                  <option value="177">Taiwan</option>
                                  <option value="178">Tajikistan</option>
                                  <option value="179">Tanzania</option>
                                  <option value="180">Thailand</option>
                                  <option value="181">Togo</option>
                                  <option value="182">Tonga</option>
                                  <option value="183">Trinidad &amp; Tobago</option>
                                  <option value="184">Tunisia</option>
                                  <option value="185">Turkey</option>
                                  <option value="186">Turkmenistan</option>
                                  <option value="187">Tuvalu</option>
                                  <option value="188">Uganda</option>
                                  <option value="189">Ukraine</option>
                                  <option value="190">United Arab Emirates</option>
                                  <option value="191">United Kingdom</option>
                                  <option value="192">United States</option>
                                  <option value="193">Uruguay</option>
                                  <option value="194">Uzbekistan</option>
                                  <option value="195">Vanuatu</option>
                                  <option value="196">Vatican City</option>
                                  <option value="197">Venezuela</option>
                                  <option value="198">Vietnam</option>
                                  <option value="199">Western Sahara</option>
                                  <option value="200">Wales</option>
                                  <option value="201">Yemen</option>
                                  <option value="202">Zaire</option>
                                  <option value="203">Zambia</option>
                                  <option value="204">Zimbabwe</option>
                                </select>

                                {error.country && <p className='error'>{error.country}</p>}
                              </div>
                              <div className="col-md-6 form-input ">
                                <label htmlFor="city" className="form-label">City <span className='text-danger'>*</span> </label>
                                <input type="text" className="form-control" required name="city" value={inputdata.city} onChange={handleinputs} id="city" placeholder='City' />
                                {error.city && <p className='error'>{error.city}</p>}

                              </div>
                            </div>
                            <div className="row from-group ">
                              <div className="col-md-6 form-input ">
                                <label htmlFor="batch" className="form-label"> Batch <span className='text-danger'>*</span> </label>
                                <select name="batch" id="batch" required value={inputdata.batch} onChange={handleinputs} >
                                  <option value="" disabled>YYYY</option>
                                  {batchyear?.map((year, idx) => (
                                    <option key={idx} value={year}>
                                      {year}
                                    </option>
                                  ))}
                                </select>
                                {error.batch && <p className='error'>{error.batch}</p>}

                              </div>
                              <div className="col-md-6 form-input ">
                                <label htmlFor="leavingYear" className="form-label">Year Of Leaving School<span className='text-danger'>*</span> </label>
                                <select name="leavingYear" required value={inputdata.leavingYear} onChange={handleinputs} id="leavingYear">
                                  <option value="" disabled>YYYY</option>
                                  {batchyear?.map((year, idx) => (
                                    <option key={idx} value={year}>
                                      {year}
                                    </option>
                                  ))}
                                </select>
                                {error.leavingYear && <p className='error'>{error.leavingYear}</p>}

                              </div>
                            </div>
                            <div className="row from-group ">
                              <div className="col-md-6 form-input ">
                                <label htmlFor="lastclass" className="form-label"> Last Class <span className='text-danger'>*</span> </label>
                                <select name="lastclass" id="lastclass" required value={inputdata.lastclass} onChange={handleinputs} className=''>
                                  <option selected="selected" value="0">Select</option>
                                  <option value="10">10th</option>
                                  <option value="11">11th</option>
                                  <option value="12">12th</option>
                                </select>
                                {error.lastclass && <p className='error'>{error.lastclass}</p>}

                              </div>
                              <div className="col-md-6 form-input ">
                                <label htmlFor="gender" className="form-label">Gender <span className='text-danger'>*</span> </label>

                                <div className="row mt-2">
                                  <div className="col-md-6">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="gender" id="male" value="male" checked={inputdata.gender === 'male'} onChange={handleinputs}
                                      />
                                      <label className="form-check-label" htmlFor="male">
                                        Male
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="gender" id="female" value="female" checked={inputdata.gender === 'female'}
                                        onChange={handleinputs}
                                      />
                                      <label className="form-check-label" htmlFor="female">
                                        Female
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {error.gender && <p className='error'>{error.gender}</p>}

                              </div>
                            </div>
                            <div className="row from-group ">
                              <div className="col-md-6 form-input ">
                                <label htmlFor="graduation" className="form-label"> Graduation </label>
                                <div className="row">
                                  <div className="col-md-4 col-sm-4 col-4 ">
                                    <select value={inputdata.graduation} onChange={handleinputs} name="graduation" id="graduation">
                                      <option value="" disabled>YYYY</option>
                                      {batchyear?.map((year, idx) => (
                                        <option key={idx} value={year}>
                                          {year}
                                        </option>
                                      ))}

                                    </select></div>
                                  <div className="col-md-8 col-sm-8 col-8">
                                    <input type="text" className="form-control" name="graduationcollege" id="graduation" value={inputdata.graduationcollege} onChange={handleinputs} placeholder='Enter College Name' />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 form-input ">
                                <label htmlFor="postgraduation" className="form-label">Post Graduation </label>
                                <div className="row">
                                  <div className="col-md-4 col-sm-4 col-4 ">
                                    <select name="postgraduation" value={inputdata.postgraduation} onChange={handleinputs} id="postgraduation">
                                      <option value="" disabled>YYYY</option>
                                      {batchyear?.map((year, idx) => (
                                        <option key={idx} value={year}>
                                          {year}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-md-8 col-sm-8 col-8">
                                    <input type="text" className="form-control" name="college_postgraduation" id="postgraduation" value={inputdata.college_postgraduation} onChange={handleinputs} placeholder='Enter College Name' />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row from-group ">
                              <div className="col-md-6 form-input ">
                                <label htmlFor="DLEd" className="form-label"> TTC/DLEd
                                </label>
                                <div className="row">
                                  <div className="col-md-4 col-sm-4 col-4 ">
                                    <select name="DLEd" value={inputdata.DLEd} onChange={handleinputs} id="DLEd" >
                                      <option value="" disabled>YYYY</option>
                                      {batchyear?.map((year, idx) => (
                                        <option key={idx} value={year}>
                                          {year}
                                        </option>
                                      ))}

                                    </select>
                                  </div>
                                  <div className="col-md-8 col-sm-8 col-8">
                                    <input type="text" name="DLEd_college" id="college" className="form-control" value={inputdata.DLEd_college} onChange={handleinputs} placeholder='Enter College Name' />

                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 form-input ">
                                <label htmlFor="BEd" className="form-label">B.Ed
                                </label>
                                <div className="row">
                                  <div className="col-md-4 col-sm-4 col-4 ">
                                    <select name="BEd" value={inputdata.BEd} onChange={handleinputs} id="BEd">
                                      <option value="" disabled>YYYY</option>
                                      {batchyear?.map((year, idx) => (
                                        <option key={idx} value={year}>
                                          {year}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-md-8 col-sm-8 col-8">
                                    <input type="text" name="BEd_college" id="BEd" className='form-control' value={inputdata.BEd_college} onChange={handleinputs} placeholder='Enter College Name' />

                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="row from-group ">
                              <div className="col-md-6 form-input ">
                                <label htmlFor="image" className="form-label"> Upload recent passport sized photo <span className='text-danger'>*</span> <br /><span className='warning'>(Max file size 2 MB)</span>  </label>

                                <input type="file" className="form-control" name="image" value={inputdata.image} onChange={handleinputs} id="image" placeholder='Image' />
                                {error.image && <p className='error'>{error.image}</p>}

                              </div>
                              <div className="col-md-6 form-input ">
                                <label htmlFor="class_Ten" className="form-label"> Upload class X registration/marksheet/admit/certificate (any one) <span className='text-danger'>*</span> <span className='warning'>(Max file size 4 MB)</span></label>
                                <input type="file" className="form-control" name="class_Ten" value={inputdata.class_Ten} onChange={handleinputs} id="class_Ten" placeholder='file' />
                                {error.class_Ten && <p className='error'>{error.class_Ten}</p>}

                              </div>
                            </div> */}
                            <br />
                            <div className=" form-check">
                              <input type="checkbox" checked={submitted ? checked1 : true} className="form-check-input" required id="exampleCheck1" name='terms_condition' onChange={() => setChecked1(!checked1)} />
                              <label className="form-check-label" htmlFor="exampleCheck1">
                                I agree to the terms of service.
                              </label>
                              {/* {error.terms_condition && <p className='error'>{error.terms_condition}</p>} */}

                            </div>


                            <div className=" form-check">
                              <input type="checkbox" checked={submitted ? checked2 : true} className="form-check-input" required id="exampleCheck4" name='personal_info' onChange={() => setChecked2(!checked2)}
                              />
                              <label className="form-check-label" htmlFor="exampleCheck4">
                                I Agree to share my personal details with OSA.
                              </label>
                              {/* {error.personal_info && <p className='error'>{error.personal_info}</p>} */}

                            </div>
                            <div className="row mt-5">
                              <div className="col-md-12 text-center">
                                <button type="submit" className="register_btn btn btn-primary "> Register  </button>
                              </div>
                            </div>
                          </form>

                        </Card>
                      </Col>
                    </Row>

                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  )
}

export default Register
