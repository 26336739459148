import React from 'react'
import PageHeader from '../Components/PageHeader'

const JointSecretary = () => {
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="Joint Secretary's Message" />
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="about_main_container">
                  <div className="row">
                    <div className="col-md-4 text-center">
                      <div className="card">
                        <div className="president_img bluebgimg_here">
                          <center><img src="/assets/images/Mr. Sunny Mangalani_Joint.png" alt="" />
                          </center>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">

                      <div className="president_msg">
                        <div className='secretary_msg'>
                          <h5 className='text-danger'> St Mary's always holds a special place in my life as my alma mater.</h5>
                        </div>
                        <p><i className="fa fa-quote-left" aria-hidden="true"></i>
                          &nbsp;
                          My school has always made me feel proud and worthy of what I am today as a grown and established individual.
                          All the ideas and values that were instilled in  me during my school days have always given me enough reasons to aspire to be a responsible contributor to society.

                          Now when I have got an opportunity to serve my Alma mater as a member of OSA, I am trying my best to return exponentially to this great place.&nbsp;



                          <i className="fa fa-quote-right" aria-hidden="true"></i>
                        </p>
                        <br />
                        <h5 className='text-end President_name'>Mr. Sunny Mangalani</h5>
                        <h5 className='text-end President_name'>Joint Secretary, OSA-SMCIC</h5>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default JointSecretary
