import React from 'react'
import PageHeader from '../Components/PageHeader'

const AboutosaContent = () => {
    return (
        <>
            <section className='history_of_osa'>
                <div className="about_osa_layer">
                    <PageHeader heading="About OSA" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about_main_container">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <div className="card">
                                                <div className="school_img">
                                                    {/* <center><img src="/assets/images/s4.jpg" alt="" />
                          </center> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="aboutschool_text">
                                                {/* <h5>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit, eum!</h5>
                        <br /> */}

                                                <p>
                                                    Our OLD STUDENTS' ASSOCIATION was started in 2002 with the support of that time Principal, Sr.Teresina, by forming a Team of Committee Members headed by Gurmeet Singh Kalra (1979) as the Founder President whose tenure lasted for two decades upto 2022.

                                                    The formation took place in continuation to the Margarita Group, initiated by our first Principal of St. Mary's School, Sr. Margarita (1964-1972) which consisted Members only from her duration of Headship.


                                                </p>
                                                <br />
                                                <p style={{ fontWeight: "500" }}>The Working committee had various responsibilities to undertake therefore Portfolios were distributed among all its members viz.
                                                </p>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <ul>
                                                            <li>Manju (Bangia) Taneja'81</li>
                                                            <li>Rajeev Kathuria</li>
                                                            <li>Tanuja (Sethia) Kumar</li>
                                                            <li>Lipika Dutta</li>
                                                            <li>Nisha (Gathoria) Seth</li>
                                                            <li>Subir Gulhati</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <ul>

                                                            <li>Manu Gogia</li>
                                                            <li>Sudipta Roy</li>
                                                            <li>Nibha Rajpal</li>
                                                            <li>Sakshi Anand</li>
                                                            <li>Harjinder Kaur</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <br />
                                                <p>With coordination from Dr. Manjula (Sethia) Mehta Later joining
                                                    Rohit Gupta,Shivalik Sehgal,Manjit Singh Virdi</p>


                                                <p style={{ fontWeight: "500" }}>There were various Events done by OSA like:</p>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <ul>
                                                            <li>Annual Get-togethers</li>
                                                            <li>Fetes</li>
                                                            <li>Inter House Crossroads</li>
                                                            <li>OSA Magazines</li>
                                                            <li>Medical Camp</li>

                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <ul>
                                                            <li>Donation Collection</li>
                                                            <li>Basketball Match</li>
                                                            <li>Tug 'o' War</li>
                                                            <li>Teachers' Honour</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p style={{ fontWeight: "500" }}>Somehow the bonding varied with each passing Principal</p>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <ul>
                                                            <li>Sr. Marina (Current)</li>
                                                            <li>Sr. Lissy (2018-23)</li>
                                                            <li>Sr. Jyoti (2015-18)</li>
                                                            <li>Sr. Roshni (2011-15)</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <ul>
                                                            <li>Sr. Jane (2006-11)</li>
                                                            <li>Sr. Elsy (2001-06)</li>
                                                            <li>Sr. Teresina (1994-2001)</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <br />
                                                <p style={{ fontWeight: "500" }}>but somehow OSA sailed through and has reached this shape with the New Team pledging to take it to new heights under the leadership of Md. Haris Siddiqui and Vikas Dalela in command followed by:</p>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <ul>
                                                            <li>Rumna Banerjee</li>
                                                            <li>Aditi Pandey </li>
                                                            <li>Sujata Khanal</li>
                                                            <li>Sr. Roshni (2011-15)</li>
                                                            <li>Anisha Budhraja</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <ul>

                                                            <li>Priya Sandhu</li>
                                                            <li>Deepika Swaroop</li>
                                                            <li>Sunny Manglani</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <br />
                                                <p style={{ fontWeight: "500" }}>With additional support from:</p>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <ul>
                                                            <li>Dr. Poonam Anand'81</li>
                                                            <li>Sumender Hora</li>
                                                            <li>Priya Rajpal'05</li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <br />
                                                <p>Harpal Singh Alagh'81 of Amar Fax has always helped OSA since 2002 till date by providing lavish & prompt, Free Printing facilities, always with a smile.
                                              The increased participation levels and excitement among the new generation will surely be a boon for the OSA. </p>
                                            
                                                <p style={{ fontWeight: "500" }}>Best wishes to all ex-Marians.</p></div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-md-12 text-center">
                                            <div className="card">
                                                <div className="school_img">
                                                    <center><img src="/assets/images/s4.jpg" alt="" />
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="row">
                                        <div className="col-md-12">
                                            <div className="history_txt_here">
                                              


                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutosaContent
