import React, { useState, useEffect } from 'react'
import PageHeader from '../Components/PageHeader'
import { Row, Col, Container } from "reactstrap";
import BlogCards from '../Components/Cards/BlogCards';
import Skeleton from 'react-loading-skeleton'

const Blog = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getblogs();
    }, []);
    const getblogs = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const api_url = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${api_url}/blog/getall`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const results = await response.json();
            setBlogs([...results].reverse());
            setLoading(false);
            // console.log(results);
        } catch (error) {
            console.error(error);
        }
    }
    // console.log(blogs);
    return (
        <>
            <section className='history_of_osa'>
                <div className="about_osa_layer">
                    <PageHeader heading=" Blog" />
                    <Container>
                        <Row>
                            <Col lg={12} md={12}>
                                <div className="about_main_container">
                                    <Row>
                                        {!loading ? (
                                            <BlogCards result={blogs} />
                                        ) : (
                                            <Skeleton height={380} count={3} style={{ marginLeft: '30px', textAlign: 'center' }} inline width={360} />
                                        )
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}

export default Blog
