import React, { useState, useEffect } from 'react'
import { Modal, ModalBody } from "reactstrap";
import './Modal.css'

const Popup = () => {

  const [modal, setModal] = useState(false);

  useEffect(() => {
    const popupClosed = sessionStorage.getItem('popupClosed');
    if (!popupClosed) {
      setModal(true);
    }
  }, []);

  const toggle = () => {
    setModal(false);
    sessionStorage.setItem('popupClosed', 'true');
  };
  return (
    <>
      <Modal isOpen={modal} size="md" toggle={toggle} >
        <ModalBody className='p-0'>
          <div className="modal_bg_img">
            <div className="modal_bg_layer">
              <h4 className='text-right '><i className='fa fa-close' onClick={toggle}></i></h4>

              <div className="modal_main_content">

                <div className="modal_welcome_content">
                  <h1>Welcome to our Old Student Community!</h1>
                </div>
                <div className="welcome_msg">
                  <p>
                    Join us in celebrating our shared memories, accomplishments,
                    and experiences. This space is more than a website; it's a vibrant
                    community that thrives on the bonds we've built and the journeys we've shared.
                    This platform is your gateway to reconnecting, reminiscing, and rekindling the connections
                    that made our alma mater special.</p>
                  <p>Welcome back! Your presence enriches our community, and we're
                    excited to continue this journey together.</p>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Popup
