import React from 'react'
import PageHeader from '../Components/PageHeader'

const Error404Page = () => {
    return (
        <>
            <section className='history_of_osa'>
                <div className="about_osa_layer">
                    <PageHeader heading="Page Not Found" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about_main_container">
                                    <div className="row">
                                        <div className="col-md-5 text-center">
                                            <div className="card">
                                                <div className="school_img">
                                                    <center><img src="/assets/images/404gif.gif" alt="" /></center>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5 text-center">
                                            <div className="col-md-12 mt-5 pt-5 "><br />
                                                <h3 className='text-danger'> Page Not Found! </h3>
                                            </div>
                                            <div className="text_here px-5 ">
                                                <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Error404Page
