import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../Components/PageHeader'
import GalleryCard from '../Components/Cards/GalleryCard'
import Skeleton from 'react-loading-skeleton'

const Gallery = () => {
    const [allImages, setAllimages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fecthImages();
    }, []);
    const fecthImages = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const apiUrl = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`https://api.osasmciclko.in/gallery/getGallery`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const results = await response.json();
            setAllimages(results);
            setLoading(false);
        } catch (error) {
            console.log(error, "images not fetch")
        }
    };

    return (
        <>
            <section className='history_of_osa'>
                <div className="about_osa_layer">
                    <PageHeader heading="Gallery" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="Gallery_main_container">
                                    <div className="row">
                                        {!loading ? (
                                            allImages && allImages.length > 0 ?
                                                (
                                                    allImages.map((Gimg, idx) => {
                                                        const encodedId = btoa(Gimg._id);
                                                        return (
                                                            <div className="col-lg-4 col-md-4 col-sm-6 col-12" key={idx}>
                                                                <Link to={`/GalleryImg/${encodedId}`} >
                                                                    <GalleryCard img={Gimg.images[0]} text={Gimg.description} />
                                                                </Link>
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    <div className="col-md-12 text-center">
                                                        <h3 className='text-danger'>Gallery Images Not Available!</h3>
                                                    </div>
                                                )

                                        ) : (
                                            <Skeleton height={300} width={300} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Gallery
