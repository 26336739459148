import React from 'react'
import PageHeader from '../Components/PageHeader'

const AboutSchool = () => {
    return (
        <>
            <section className='history_of_osa'>
                <div className="about_osa_layer">
                    <PageHeader heading="About School" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about_main_container">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <div className="card">
                                                <div className="school_img">
                                                    {/* <center><img src="/assets/images/s4.jpg" alt="" />
                          </center> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="aboutschool_text">
                                                {/* <h5>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit, eum!</h5>
                        <br /> */}
                                                <p>With the noble vision of building a better world, St. Mary's Convent Inter
                                                    College, RDSO, Lucknow began its journey in the year 1964. It was the year
                                                    when the Central Government desired to move the various components ofthe
                                                    Railway research work to Lucknow. Bishop Conrad De Vito, the then Bishop
                                                    of the Catholic diocese of Lucknow, at the request of the Ministry of
                                                    Railways, invited the Sisters of the Congregation of Jesus to open an English
                                                    Medium school in the colony of Research, Designs and Standard
                                                    Organization that was yet to be built.
                                                </p>
                                                <p>The College has come a long way since its inception in 1964, playing a stellar
                                                    role in imparting quality education to create a Just Society. With the vision of
                                                    spreading God's message of Love, Peace, Unity and Brotherhood.
                                                    Today St. Mary’s Convent Inter College, Lucknow, stands tall as a premiere
                                                    Institution of quality and a catalyst of social- cultural, moral and religious
                                                    Values that constantly influence and transform the society. Generations of
                                                    Students who have passed through the hallowed portals of this great temple
                                                    Of learning bear witness to this. As we step into the 55th  year of our existence
                                                    On the educational map of India it is our hope that guided by the vision of our
                                                    Venerable Foundress Mary Ward, SMCIC family will continue to play a
                                                    Pivotal role in social transformation, by imparting education to generations
                                                    Yet to come with devotion, dedication and comnmitment.
                                                </p>
                                                
                                                <h5 className='mt-5'>ALL FOR THE GREATER HONOUR AND GLORY OF GOD</h5>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-md-12 text-center">
                                            <div className="card">
                                                <div className="school_img">
                                                    <center><img src="/assets/images/s4.jpg" alt="" />
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="row">
                                        <div className="col-md-12">
                                            <div className="history_txt_here">
                                              


                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutSchool
