import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../Modals/Modal.css'
import ModalSection from '../Modals/ModalSection';

const CardThree = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    return (
        <>
            <div className="col-lg-3 col-md-6 col-sm-12" onClick={toggle}>
                <div className="card features_box">
                    <Link to={''}>
                        <div className="icon">
                            <center>
                                <img src="/assets/images/icon/blog.png" alt="" />
                            </center>

                        </div>
                        <div className="bottom_txt">
                            <h5>My Blog</h5>
                            {/* <p>Our OSA Members  can can share blog
                            </p> */}
                        </div>
                    </Link>
                </div>
            </div>
            <ModalSection isOpen={modal} toggle={() => setModal(!modal)} heading="My Blog"
                text="Dear friends, it's time to unleash the imagination and add it 
                    to the literary world. The Canvas here is calling you to resonate 
                    through the blogosphere.  Let the syntex dance and the music of language reach out 
                    to our marians to bring warmth to their heart and peace to their minds." link="/Blog" linktext="Read More Blogs"
            />
        </>
    )
}

export default CardThree
