import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import './Mobilemenu.css'

const Navbar = () => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSticky, setSticky] = useState(false);
    const [click, setClick] = useState(false);
    const [subMenuOpen, setSubMenuOpen] = useState(false);

    const handleScroll = () => {
        setSticky(window.scrollY > 100);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const toggleAudio = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };
    useEffect(() => {
        toggleAudio();
        return () => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        };
    }, []);
    const toggleSubMenu = () => {
        setSubMenuOpen(!subMenuOpen);
    };
    const handleClick = () => {
        setClick(!click);
        document.body.style.overflow = click ? 'auto' : 'hidden';
    };
    const Close = () => setClick(false);
    return (
        <>
            <div className="mobile_menu_visible">
                <header className={` sticky-menu ${isSticky ? 'sticky' : ''} site-header`}>
                    <div className="mobile-menu-new">
                        <div className={click ? "main-container" : ""} onClick={() => Close()} />
                        <nav className="navbar" onClick={e => e.stopPropagation()}>
                            <div className="nav-container">
                                <Link to="/" rel="home" className="main-logo">
                                    <img
                                        id="logo_header"
                                        src="/assets/images/flogo.png" className="sitemain_logo"
                                        alt="gaming"
                                    />
                                </Link>
                                <div className="schoolname_txt">
                                    <h5><Link to="/">OSA SMCIC</Link></h5>
                                </div>
                                <ul className={click ? "nav-menu active" : "nav-menu"}>
                                    <li className="nav-item">
                                        <Link
                                            exact
                                            to="/"
                                            activeClassName="active"
                                            className="nav-links"
                                            onClick={click ? handleClick : null}
                                        >
                                            <i className='fa fa-home'></i>  <span>Home</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-links" onClick={toggleSubMenu}> <i className="fa-solid fa-circle-info"></i> <span>About OSA</span>
                                            <span className='angledown_icon'> <i className='fa-solid fa fa-angle-down text-right'></i>
                                            </span>
                                        </Link>
                                        {subMenuOpen && (
                                            <ul className='sub-menu'>
                                                <li className='submenu-link'>
                                                    <Link exact to="/PresidentMsg" activeClassName="active" onClick={click ? handleClick : null}>president's message</Link>
                                                </li>
                                                <li className='submenu-link'>
                                                    <Link exact to="/VicePresidentmsg" activeClassName="active" onClick={click ? handleClick : null}>vice president's  message</Link>
                                                </li>
                                                <li className='submenu-link'>
                                                    <Link exact to="/SecretaryMsg" activeClassName="active" onClick={click ? handleClick : null}>secretary's message</Link>
                                                </li>
                                                <li className='submenu-link'>
                                                    <Link exact to="/Joint_SecretaryMsg" activeClassName="active" onClick={click ? handleClick : null}>Joint secretary's message</Link>
                                                </li>
                                                <li className='submenu-link'>
                                                    <Link exact to="/OfficeBearers" activeClassName="active" onClick={click ? handleClick : null}>office bearers/Editorial board</Link>
                                                </li>
                                                <li className='submenu-link'>
                                                    <Link exact to="/Founder_PresidentMsg" activeClassName="active" onClick={click ? handleClick : null}>founder  president's  message</Link>
                                                </li>
                                                <li className='submenu-link'>
                                                    <Link exact to="/Founder_SecretaryMsg" activeClassName="active" onClick={click ? handleClick : null}>founder  secretary's  message</Link>
                                                </li>
                                                <li className='submenu-link'>
                                                    <Link exact to="/AdvisoryMembers" activeClassName="active" onClick={click ? handleClick : null}>advisory  members</Link>
                                                </li>
                                            </ul>
                                        )}
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            exact
                                            to="/Register"
                                            activeClassName="active"
                                            className="nav-links"
                                            onClick={click ? handleClick : null}
                                        >   <i className="fa-solid fa-registered"></i>

                                            <span>Registration</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            exact
                                            to="/OurAchievers"
                                            activeClassName="active"
                                            className="nav-links"
                                            onClick={click ? handleClick : null}
                                        >
                                            <i className="fa-solid fa-award"></i>
                                            <span>Our Achievers</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            exact
                                            to="/AlumniDirectory"
                                            activeClassName="active"
                                            className="nav-links"
                                            onClick={click ? handleClick : null}
                                        >
                                            <i className="fa-solid fa-user-graduate"></i>

                                            <span>Alumni Directory</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            exact
                                            to="/SchoolAnthem"
                                            activeClassName="active"
                                            className="nav-links"
                                            onClick={click ? handleClick : null}
                                        >
                                            <i className="fa-solid fa-book-open"></i>

                                            <span>School Anthem</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link
                                            exact
                                            to="/ContactUs"
                                            activeClassName="active"
                                            className="nav-links"
                                            onClick={click ? handleClick : null}
                                        >
                                            <i className="fa-solid fa-address-card"></i>
                                            <span>Contact Us</span>
                                        </Link>
                                    </li>
                                </ul>
                                <div className="nav-icon" onClick={handleClick}>
                                    <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
            </div>
        </>
    )
}

export default Navbar
