import React, { useState } from 'react'
import PageHeader from '../Components/PageHeader'
import Validation from '../Validation/ContactForm'
import { toast } from 'react-toastify';

const Suggestion = () => {
  const [formdata, setFormdata] = useState({
    username: "",
    mobile: "",
    email: "",
    msg: ""
  });

  const [error, setError] = useState({});
  const [records, setRecords] = useState([]);

  const onchangecontact = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const newdata = { ...formdata, [name]: value };
    setFormdata(newdata);

  }
  const handleform = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        name: formdata.username,
        mobile_number: formdata.mobile,
        email: formdata.email,
        msg: formdata.msg,
        type: "Suggestion"
      }
      console.log(payload, "PL");
      // return;
      const base_url = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${base_url}/contacts/insert`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (response.status === 200) {
        toast.success('Thanks for your response, your message has been successfully recorded!', { autoClose: 2000 })
        console.log(response.statusText, 'Form submitted successfully');
      } else {
        toast.error('Something went wrong!', { autoClose: 2000 })

      }
    } catch (error) {
      toast.error('Something went wrong!', { autoClose: 2000 })

      console.error('Error submitting form:', error);
    }
    const newrecords = { ...formdata, id: new Date().getTime().toString() }
    setRecords([...records, newrecords])
    setFormdata({ username: "", mobile: "", email: "", Subject: "", msg: "" });

  }
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="Suggestion" />
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="suggestion_main_container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="row">
                          <div className="col-lg-3 col-md-2"></div>
                          <div className="col-lg-6 col-md-8 suggestion_page_ui">
                            <div className="contactus_form">
                              <h3 className='suggestion_heading text-center '>Give Your Suggestion</h3>

                              <form onSubmit={handleform} className='suggestionpage contactform'>
                                <div className="mb-3">
                                  <label htmlFor="name" className="form-label">
                                    Name <span className='text-danger'>*</span>
                                  </label>
                                  <input type="text" className="form-control" required name="username" value={formdata.username} onChange={onchangecontact} id="name" placeholder='Enter Name' />
                                  {/* {error.username && <p className='error'>{error.username}</p>} */}

                                </div>
                                <div className="mb-3">
                                  <label htmlFor="mobile" className="form-label">
                                    Mobile No.<span className='text-danger'>*</span>
                                  </label>
                                  <input type="number" className="form-control" required name="mobile" value={formdata.mobile} onChange={onchangecontact} id="mobile" placeholder='Enter Mobile No.' />
                                  {/* {error.mobile && <p className='error'>{error.mobile}</p>} */}

                                </div>
                                <div className="mb-3">
                                  <label htmlFor="exampleInputEmail1" className="form-label">
                                    Email <span className='text-danger'>*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control" name="email" required value={formdata.email} onChange={onchangecontact}
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder='Enter Email'
                                  />

                                  {/* {error.email && <p className='error'>{error.email}</p>} */}

                                </div>

                                <div className="mb-3">
                                  <label htmlFor="msg" className="form-label">
                                    Suggestion <span className='text-danger'>*</span>
                                  </label>
                                  <textarea name="msg" id="msg" cols="10" required placeholder='Write Suggestion Here' className="form-control" value={formdata.msg} onChange={onchangecontact} rows="5"></textarea>

                                  <p className='text-end suggestion_Size'>Maximum 250 Words</p>
                                  {/* {error.msg && <p className='error'>{error.msg}</p>} */}

                                </div>
                                <div className="mb-3 text-center mt-5">
                                  <button type="submit" className="register_btn btn btn-primary ">
                                    Send <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Suggestion
