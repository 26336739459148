import React from 'react'
import PageHeader from '../Components/PageHeader'

const SchoolAnthem = () => {
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="School Anthem" />

          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="about_main_container">
                  <div className="row">
                  <div className="col-md-3"></div>
                    <div className="col-md-6 col-sm-12 col-12 text-center">
                      <div className="president_msg">
                        <p>The Bells of St. Mary's ring out far and wide, <br />
                          Their chimes make our childhood so happy and bright. <br />
                          They welcome young scholars to work and to play. <br />
                          They call us to duty, they call us to pray. <br /> <br />
                          Refrain: O Bells of St. Mary's we hear you repeating <br />
                          The dear song of gladness, of sweet memories. <br />
                          You tell us of striving, of frank and dair dealing. <br />
                          You sing to us of truth and love and victory. <br /><br />
                          In the school of St. Mary's how happy are we, <br />
                          We help one another life's duty to see. <br />
                          In the school of St. Mary's we find strength and light. <br />
                          To fight the good fight and to do what is right. <br /><br />
                          The tune of the school anthem has been taken from the song of <br />
                          the movie "The Bells of St. Mary's" (1945).
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default SchoolAnthem
