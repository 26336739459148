import React from 'react'
import PageHeader from '../Components/PageHeader'

const FounderPresidentMsg = () => {
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="Founder President's  Message" />

          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="about_main_container">
                  <div className="row">
                    <div className="col-md-4 text-center">
                      <div className="card">
                        <div className="president_img bluebgimg_here">
                          <center><img src="assets/images/gallery/founder.png" alt="" className='ex_presindent_msg_img' />
                          </center>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="president_msg">
                        <h5 className='founder_president_msg'>Dream, Vision, Resources and Dedication are the Four Pillars on which our Old Students' Association stands. </h5>
                        <p><i className="fa fa-quote-left" aria-hidden="true"></i>
                          &emsp; Dream came after seeing our Founder Principal, Sr. Margarita collecting pupils admitted during her tenure between 1965 & 1972.

                          Vision was that one day SMCIC and OSA would walk hand in hand.

                          Resources were Space, Cooperation, Finances & Manpower which though abundantly available, required direction and a positive attitude.

                          Dedication would come only after motivation because a selfless service had to be rendered.

                          Understanding the values of these Four Pillars, a meandering Success Journey was initiated in 2002 which is now changing gears very fast and would soon be on an Auto Mode.

                          After a two-decade-long journey as the President, one thing became crystal clear that the accelerating factor is directly proportional to the cooperation received from our Alma Mater where both are the beneficiaries. <i className="fa fa-quote-right" aria-hidden="true"></i> </p>
                        <p className='ex_presindent_msg_'>Long live our St. Mary's Convent Inter College! </p>
                        <p className='ex_presindent_msg_'>Long live our Old Students' Association!</p>

                        <h5 className='text-end President_name'>Mr Gurmeet Singh Kalra</h5>
                        <h5 className='text-end President_name'>Founder President, OSA-SMCIC</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FounderPresidentMsg
