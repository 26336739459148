export default function Validation(formdata) {
    const error = {}

    // const email_pattern = /^[s@]+@[^s@]+\.[s@]{2.6}$/;

    if (!formdata.username) {
        error.username = " Name is Required!";
    }
    if (!formdata.mobile) {
        error.mobile = "Mobile Number is Required!";
    }
    // else if (!formdata.mobile > 10) {
    //     error.mobile = "Mobile Number is no more than 10 digits";
    // }
    // else if (!formdata.mobile < 10) {
    //     error.mobile = "Mobile Number is at least 10 digits";
    // }
    if (!formdata.email) {
        error.email = "Email is Required";
    }
    // else if (!email_pattern.test(formdata.email)) {
    //     error.email = "Enter a valid email address";
    // }

    if (!formdata.Subject) {
        error.Subject = " Subject is Required!";
    }
    if (!formdata.msg) {
        error.msg = "This field  is Required";
    }
    else if (!formdata.msg > 250) {
        error.msg = "No more than 250 words ";
    }

    return error;
}
