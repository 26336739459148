import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ModalSection from '../Modals/ModalSection';

const CardTwo = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
        <>
            <div className="col-lg-3 col-md-6 col-sm-12" onClick={toggle}>
                <div className="card features_box">
                    <Link to={''}>
                        <div className="icon">
                            <center>
                                <img src="assets/images/icon/2.png" alt="" />
                            </center>

                        </div>
                        <div className="bottom_txt">
                            <h5>Invite Friends</h5>
                            {/* <p>Our OSA Members can invite a friends
                            </p> */}
                        </div>
                    </Link>
                </div>
            </div>
            <ModalSection isOpen={modal} toggle={() => setModal(!modal)} heading="Invite Friends"
                topheading=<span>"Remember that life's most valuable antiques are dear old friends."   <b>— H. Jackson Brown, Jr.</b></span>
                text=" So friends stay connected to your schoolmates and invite them to OSA with the following link and continue to share the love, joys, sorrows, and happiness till eternity."
            />
        </>
    )
}

export default CardTwo
