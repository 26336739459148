import React from 'react'
import PageHeader from '../Components/PageHeader'

const ExSecretaryMsg = () => {
    return (
        <>
            <section className='history_of_osa'>
                <div className="about_osa_layer">
                    <PageHeader heading="Founder Secretary's Message" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about_main_container">
                                    <div className="row">
                                        <div className="col-md-4 text-center">
                                            <div className="card">
                                                <div className="president_img bluebgimg_here">
                                                    <center><img src="assets/images/gallery/ManjuTaneja.png" alt="" className='ex_presindent_msg_img' />
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="president_msg">
                                                <div className='secretary_msg'>
                                                    <h5 className='text-danger'>"Cherish God's vocation in you, let it be constant, efficacious and loving". Mary Ward</h5>
                                                </div>
                                                <p><i className="fa fa-quote-left" aria-hidden="true"></i>
                                                    &emsp;
                                                    I feel privileged to have been a part of this great institution, St. Mary's Convent Inter College as a student, a teacher and as the secretary of Old Students' Association.
                                                    Foundations of my personality were laid down in the classrooms of SMCIC from Prep to class 10,under the able guidance of competent Sisters and teachers.
                                                    From August 1994 till June 2022, I served my Alma Mater as a teacher and since July 2022 am the Chief Co- Ordinator for Mary Ward School.
                                                    I have been an active member of OSA, SMCIC since its inception. With the help of my co- operative and efficient team, we were able to organize Talent Hunt Competitions and fete in school,honour our Respected teachers on Teacher's Day, organize medical camps for the lesser privileged and helped the students of Mary Ward School  celebrate Children's Day.

                                                    &emsp;
                                                    <i className="fa fa-quote-right" aria-hidden="true"></i>
                                                </p>

                                                <p className='last_word mt-4'>Wishing SMCIC Lko and OSA scale greater heights.</p>
                                                <br />
                                                <h5 className='text-end President_name'>Mrs. Manju Taneja</h5>

                                                <h5 className='text-end President_name'>Founder Secretary, OSA-SMCIC</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ExSecretaryMsg
