export default function ValidationRegister(inputdata) {
    const error = {}

    const email_pattern = /^[s@]+@[^s@]+\.[s@]{2.6}$/;

    if (inputdata.fname === "") {
        error.fname = " First Name is Required!";
    }
    if (inputdata.lname === "") {
        error.lname = " Last Name is Required!";
    }
    if (inputdata.dob === "") {
        error.dob = "Date of birth is Required!";
    }
    if (inputdata.mobile === "") {
        error.mobile = "Mobile Number is Required!";
    }
   
    else if (inputdata.mobile > 10) {
        error.mobile = "Mobile Number is no more than 10 digits";
    }
    else if (inputdata.mobile < 10) {
        error.mobile = "Mobile Number is at least 10 digits";
    }
    if (inputdata.whatsappNo === "") {
        error.whatsappNo = "WhatsApp  Number is Required!";
    }
    else if (inputdata.whatsappNo > 10) {
        error.whatsappNo = "WhatsApp Number is no more than 10 digits";
    }
    else if (inputdata.whatsappNo < 10) {
        error.whatsappNo = "WhatsApp Number is at least 10 digits";
    }
    if (inputdata.email === "") {
        error.email = "Email is Required";
    }
    else if (!email_pattern.test(inputdata.email)) {
        error.email = "Enter a valid email address";
    }

    if (inputdata.country === "") {
        error.country = " Country  is Required!";
    }
    if (inputdata.city === "") {
        error.city = " City  is Required!";
    }
    if (inputdata.batch === "") {
        error.batch = " Batch  is Required!";
    }
    if (inputdata.leavingYear === "") {
        error.leavingYear = " This Field  is Required!";
    }
    if (inputdata.lastclass === "") {
        error.lastclass = "Last Class  is Required";
    }
    if (inputdata.gender === "") {
        error.gender = " This field   is Required";
    }
    if (inputdata.image === "") {
        error.image = " This field is Required";
    }
    if (inputdata.class_Ten === "") {
        error.class_Ten = " This field  is Required";
    }
    if (!inputdata.terms_condition) {
        error.terms_condition = " Agree with our terms & condition ";
    }
    if (!inputdata.personal_info) {
        error.personal_info = " Agree to share you personal information with us";
    }

    return error;
}