import React from 'react'

const Gcards = (props) => {
    return (
        <>
            <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                <div className="card" style={{ width: "100%" }}>
                    <div className="card-img">
                        <img src={props.img} className="card-img-top" alt="..." />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">{props.text}</h5>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Gcards
