import React from 'react'
import { Link } from 'react-router-dom'

const PageHeader = (props) => {
    return (
        <>
            <div className="page_haeder">
                <div className="page_header_layer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className='page_header_heading'>
                                    <h1>{props.heading}</h1>
                                    <p><Link to="/"><i className="fa-solid fa-house"></i> Home</Link> <i className="fa-solid fa-angle-right"></i> {props.heading}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageHeader
