import React from 'react'
import PageHeader from '../Components/PageHeader'


const OurAchievers = () => {
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="Our Achievers " />

          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="Achievers_main_container">
                  <div className="row">

                    <div className="col-md-12 text-center">
                      <h3 className='text-danger'>Coming Soon</h3>

                    </div>
                    {/* <AcieversCards />
                    <AcieversCards />
                    <AcieversCards />
                    <AcieversCards />
                    <AcieversCards />
                    <AcieversCards />
                    <AcieversCards />
                    <AcieversCards /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default OurAchievers
