import React, { useRef, useEffect, useState } from 'react'
import NewsCard from './NewsCard';
import moment from 'moment';

const NewsSection = () => {
    const [newsdetail, setNewsdetail] = useState([]);
    const marquee = useRef();
    useEffect(() => {
        fetchnews();
    }, []);
    const fetchnews = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const api_url = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${api_url}/news/getnews`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const results = await response.json();
            setNewsdetail([...results].reverse());
        } catch (error) {
            console.error(error);
        }
    };
    function handleMarqueeStop(action) {
        //  console.log("hovering")
        if (action === 'stop') {
            marquee.current.stop()
        }
        else
            if (action === 'start') {
                marquee.current.start()
            }
    }
    return (
        <>
            <section className='news_section_box background_img'>
                <div className="background_layer">
                    <div className="container ">
                        <div className="row ">
                            <div className="col-lg-7 text-cenetr">
                                <div className="top_heading mt-4">
                                    <h3>News & Event</h3>
                                </div>
                                <div className="new_and_eventssection">
                                    <div className="new_event_here">
                                        <marquee ref={marquee} direction="up" loop="true" onMouseOver={() => handleMarqueeStop('stop')} onMouseLeave={() => handleMarqueeStop('start')} scrollamount={8}
                                            width="100%"
                                            className="news_marquee">
                                            <ul className="school_news" style={{ overflow: "inherit" }}>
                                                {newsdetail && newsdetail.length > 0 ?
                                                    (
                                                        newsdetail?.map((newsData, idx) => {
                                                            const ndate = moment(newsData.createdAt);
                                                            const formatteddt = ndate.format('DD-MM-YYYY');
                                                            const parsedDate = moment(formatteddt, 'DD-MM-YYYY');
                                                            const day = parsedDate.format('DD');
                                                            const month = parsedDate.format('MMM');
                                                            return (
                                                                <NewsCard key={idx} nheading={newsData.heading} txt={newsData.description} month={month} day={day} />
                                                            )
                                                        })
                                                    ) : ('')

                                                }
                                            </ul>
                                        </marquee>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-5">
                                <div className="top_heading mt-4 mb-3">
                                    <h3>Volunteers</h3>
                                </div>

                                <div className="toppers_section_here pt-10">

                                    <p className=''><i className="fa fa-quote-left " aria-hidden="true"></i> &nbsp; As members of the OSA alumni family, we appreciate your dedication to lending a hand in the name of your Alma matter.
                                        Serving as an alumni, or volunteer is ingrained in us all, it is a commitment to working in the best interest of the school, and not for personal, political, third-party, or financial gain.
                                        We strive to foster leaders and citizens who will challenge the present and enrich the future.
                                        As Alumina volunteers, we expect you to wholeheartedly, fulfill your moral obligations as OSA volunteer by embodying this mission and executing your volunteer work with it in mind.
                                        As an alumnus, and volunteer you also support and represent the commitment and motto of Efforts Spell Success.
                                        This alumni volunteer platform is created to honor individual identities by offering opportunities that celebrate different strengths, abilities, and contributions. So let’s together strive to create a more diverse, equitable, and inclusive world. &nbsp;<i className="fa fa-quote-right" aria-hidden="true"></i>
                                    </p>
                                    {/* <SwiperCard /> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewsSection
