
const Alldata = 
    [
        {
            id: 1,
            name: "Sanjay Kumar Singh",
            year: "1978",
            House: "Jyoti",
            Profession: "Mining Engr Director Technical",
            organization: "Coal India Ltd",
            city: "Ranchi"
        },
        {
            id: 2,
            name: "Poonam Anand",
            year: "1981",
            House: "Nitya",
            Profession: "Doctor",
            organization: "Freelance Mariner’s medical examiner",
            city: "Lucknow "
        },
        {
            id: 3,
            name: "Shashi Kumar Bhasker ",
            year: "1981",
            House: "Jwala ",
            Profession: "Doctor  (Ophthalmology)",
            organization: "King George's Medical University,  Lucknow",
            city: "Lucknow "
        },
        {
            id: 4,
            name: "Dr Poonam Anand",
            year: "1981",
            House: "Nitya",
            Profession: "Medical , General Practitioner ",
            organization: "Freelance ",
            city: "Lucknow "
        },
        {
            id: 5,
            name: "Manju Taneja",
            year: "1981",
            House: "Jwala",
            Profession: "Principal Mary Ward School, St Mary’s Convent ",
            organization: "St Mary’s Convent rdso lko",
            city: "Lucknow"
        },
        {
            id: 6,
            name: "BHUPINDER PAL SINGH JAGGI ",
            year: "1981",
            House: "Jyoti",
            Profession: "Service ",
            organization: "RT Pharma",
            city: "Pune"
        },
        {
            id: 7,
            name: "Tarun Sahani",
            year: "1983",
            House: "Jwala",
            Profession: "Business (proprietor)",
            organization: "TVM FOODS",
            city: "Lucknow "
        },
        {
            id: 8,
            name: "Anuj Khera ",
            year: "1984",
            House: "Nitya ",
            Profession: "Corporate professional ",
            organization: "Concentrix+Webhelp",
            city: "Gurgaon "
        },
        {
            id: 9,
            name: "DEVYANI AUTI",
            year: "1984",
            House: "NIRMALA",
            Profession: "HOMEMAKER",
            organization: "NA",
            city: "DUBAI"
        },
        {
            id: 10,
            name: "SONILA NIGAM ",
            year: "1984",
            House: "Nirmala",
            Profession: "Homemaker ",
            organization: "NA",
            city: "Lucknow "
        },
        {
            id: 11,
            name: "Kulvinder Singh Monga",
            year: "1984",
            House: "Nirmala",
            Profession: "Orthopaedic Surgeon",
            organization: "Monga Orthopaedic Centre",
            city: "Lucknow "
        },
        {
            id: 12,
            name: "ROMI BANERJEE ",
            year: "1984",
            House: "Nitya",
            Profession: "TEACHER",
            organization: "LUCKNOW PUBLIC SCHOOL",
            city: "Lucknow"
        },
        {
            id: 13,
            name: "Sarita Bhaskar ",
            year: "1985",
            House: "Jyoti ",
            Profession: "Administration , Coordinator cms",
            organization: " CMS kanpur road ",
            city: "Lucknow "
        },
        {
            id: 14,
            name: "Jayant Chowdhuri ",
            year: "1987",
            House: "Jwala",
            Profession: "School Principal ",
            organization: "The Cambrian School ",
            city: "Ayodhya"
        },
        {
            id: 15,
            name: "R Shivani Singhal ",
            year: "1987",
            House: "Jyoti (Yellow)",
            Profession: "Teacher ",
            organization: "GD Goenka Public School ",
            city: "Lucknow "
        },
        {
            id: 16,
            name: "ANITHA PAI",
            year: "1988",
            House: "NITYA",
            Profession: "ENTREPRENEUR",
            organization: "ACE TUTORIALS / HEART AND SOUL EVENTS",
            city: "LUCKNOW"
        },
        {
            id: 17,
            name: "Ruchi Mohan ",
            year: "1988",
            House: "Jyoti ",
            Profession: "Principal ",
            organization: "M.G.Convent(taken break due to health issue)",
            city: "Lucknow "
        },
        {
            id: 18,
            name: "Sandip Bhattacharya ",
            year: "1988",
            House: "Nirmala",
            Profession: "SALES PROFESSIONAL - Building Materials Regional Sales Head",
            organization: "Biltech Building Elements Limited ",
            city: "Kolkata "
        },
        {
            id: 19,
            name: "Soma Misra",
            year: "1988",
            House: "Nitya",
            Profession: "Specialist ",
            organization: "Asian Development Bank",
            city: "Manila, Philippines "
        },
        {
            id: 20,
            name: "Rohit Gupta",
            year: "1989",
            House: "Jwala",
            Profession: "Business",
            organization: "RS AUTOMOBILES HERO BIKES SHOWROOM ",
            city: "Lucknow"
        },
        {
            id: 21,
            name: "Sangeeta Sharma",
            year: "1989",
            House: "Nirmala ",
            Profession: "Education, Pre primary Primary Incharge ",
            organization: "Springdale college LDA branch ",
            city: "Lucknow"
        },
        {
            id: 22,
            name: "Poonam Jaggi ",
            year: "1989",
            House: "Nirmala",
            Profession: "Homemaker ",
            organization: "NA",
            city: "Lucknow "
        },
        {
            id: 23,
            name: "Reena Bhayana ",
            year: "1989",
            House: "Jwala",
            Profession: "Homemaker ",
            organization: "NA",
            city: "Lucknow "
        },
        {
            id: 24,
            name: "Parvinder kaur kalsi",
            year: "1989",
            House: "Nirmala",
            Profession: "Housemaker",
            organization: "House",
            city: "Aurangabad "
        },
        {
            id: 25,
            name: "MANIKA KHANNA ",
            year: "1989",
            House: "Jwala",
            Profession: "Presently i am not working but have worked as an english teacher in ICSE and cbse schools for 15years",
            organization: "St.joseph's convent, hoshiarpur ",
            city: "Greater Noida "
        },
        {
            id: 26,
            name: "Anuradha Manik",
            year: "1990",
            House: "Jwala",
            Profession: " Ex Teacher",
            organization: "CMS",
            city: "Lucknow"
        },
        {
            id: 27,
            name: "Rumna Banerjee",
            year: "1990",
            House: "Nitya",
            Profession: "Assistant teacher",
            organization: "SMCIC, LKO",
            city: "Lucknow"
        },
        {
            id: 28,
            name: "Sumendar hora ",
            year: "1990",
            House: "Jwala",
            Profession: "Business ",
            organization: "Subhash the mithai shop: SPICY HUB ",
            city: "Lucknow "
        },
        {
            id: 29,
            name: "Anamita Mandal ",
            year: "1990",
            House: "Nirmala ",
            Profession: "Doctor MD Dermatology ",
            organization: "Skin and hair clinic ",
            city: "Lucknow "
        },
        {
            id: 30,
            name: "Vikas Mehan",
            year: "1990",
            House: "Jawala House ",
            Profession: "Financial Consultant ",
            organization: "Self Employed",
            city: "Lucknow "
        },
        {
            id: 31,
            name: "Era Kapoor",
            year: "1990",
            House: "Jwala",
            Profession: "General Manager -Technical",
            organization: "Encardio-rite Electronics Pvt. Ltd.",
            city: "Lucknow"
        },
        {
            id: 32,
            name: "Perminder Singh",
            year: "1990",
            House: "Jwala",
            Profession: "IT, System Manager, ",
            organization: "Uttar Pradesh Metro Rail Corporation Ltd.",
            city: "Lucknow "
        },
        {
            id: 33,
            name: "Era Kapoor",
            year: "1990",
            House: "Jwala",
            Profession: "Job, General Manager-Technical Communications",
            organization: "Encardi-Rite Electronics Pvt. Ltd.",
            city: "Lucknow"
        },
        {
            id: 34,
            name: "Shweta Prasad",
            year: "1990",
            House: "Jwala",
            Profession: "Leadership coach & HR Consultant ",
            organization: "Asia Coaching Network",
            city: "Mumbai"
        },
        {
            id: 35,
            name: "Sandeep Tiwari ",
            year: "1990",
            House: "Jyoti",
            Profession: "Marketing , Regional Sales Head , ACC Cement ",
            organization: "ACC Cement ",
            city: "Kanpur"
        },
        {
            id: 36,
            name: "Ashish Dhawan",
            year: "1990",
            House: "Nitya",
            Profession: "Marketing Distributor ",
            organization: "Enagic kangen water ",
            city: "Lucknow "
        },
        {
            id: 37,
            name: "Urmi Sarkar ",
            year: "1990",
            House: "Nirmala",
            Profession: "PAEDIATRICIAN, ACMS",
            organization: "NR Divisional Hospital ",
            city: "LUCKNOW"
        },
        {
            id: 38,
            name: "Avinash kumar Srivastava",
            year: "1990",
            House: "Jyoti",
            Profession: "Service Assistant Manager",
            organization: "Genpact",
            city: "Lucknow"
        },
        {
            id: 39,
            name: "SHEFALI SINGH",
            year: "1990",
            House: "NITYA ",
            Profession: "SUBJECT SUPERVISOR ",
            organization: "THE LUCKNOW PUBLIC COLLEGIATE ",
            city: "LUCKNOW "
        },
        {
            id: 40,
            name: "SHEFALI SINGH ",
            year: "1990",
            House: "NITYA",
            Profession: "SUBJECT SUPERVISOR ",
            organization: "THE LUCKNOW PUBLIC COLLEGIATE ",
            city: "LUCKNOW "
        },
        {
            id: 41,
            name: "Perminder Singh",
            year: "1990",
            House: "Jwala",
            Profession: "Systems Manager IT",
            organization: "Uttar Prdesh Metro Rail Corporation Ltd, Lucknow ",
            city: "Lucknow "
        },
        {
            id: 42,
            name: "Anju kapoor ",
            year: "1990",
            House: "Jwala",
            Profession: "Teacher : senior Incharge",
            organization: "SR Capital Public school",
            city: "Delhi"
        },
        {
            id: 43,
            name: "Sarika Agarwal ",
            year: "1991",
            House: "Jyoti ",
            Profession: "Consultant obstetrician and gynaecologist ",
            organization: "Saanidhya Medicare ",
            city: "Lucknow "
        },
        {
            id: 44,
            name: "Dr.Jesna Manoj",
            year: "1991",
            House: "Jyoti",
            Profession: "Doctor- Psychiatrist",
            organization: "Private sector and Nipccd ,Lucknow",
            city: "Lucknow"
        },
        {
            id: 45,
            name: "Archana Dhir",
            year: "1991",
            House: "Jwala",
            Profession: "Dy Director, Corporate Resource Centre",
            organization: "Amity University Lucknow",
            city: "Lucknow "
        },
        {
            id: 46,
            name: "Pooja Batas ",
            year: "1992",
            House: "Nirmala",
            Profession: "Fashion Designer",
            organization: "Taanabaana by Pooja Batas ",
            city: "Lucknow"
        },
        {
            id: 47,
            name: "Kusha Das ",
            year: "1992",
            House: "Nirmala ",
            Profession: "Personal Success Coach ",
            organization: "Empowering Visions",
            city: "Lucknow "
        },
        {
            id: 48,
            name: "Neetu Nakra",
            year: "1992",
            House: "Nitya",
            Profession: "Teacher ",
            organization: "Self coaching classes ",
            city: "Lucknow "
        },
        {
            id: 49,
            name: "Amit Chakravorty",
            year: "1993",
            House: "Jyoti",
            Profession: "IT professional ",
            organization: "IBM",
            city: "Noida"
        },
        {
            id: 50,
            name: "Roopali Barua ",
            year: "1993",
            House: "Nirmala ",
            Profession: "Physics Teacher (9th n 10th)",
            organization: "SKD Academy ",
            city: "Lucknow "
        },
        {
            id: 51,
            name: "Manjeet Singh Virdi",
            year: "1993",
            House: "Jyoti (Yellow)",
            Profession: "Proprietor ASIAN BATTERY AGENCY ",
            organization: "ASIAN BATTERY AGENCY ",
            city: "Lucknow "
        },
        {
            id: 52,
            name: "Roopali Barua ",
            year: "1993",
            House: "Nirmala",
            Profession: "Teacher PGT Physics ",
            organization: "SKD Academy ",
            city: "Lucknow "
        },
        {
            id: 53,
            name: "Amit Madan ",
            year: "1994",
            House: "Nirmala",
            Profession: "Dermatologist,  Senior Consultant ",
            organization: "Madan's Skin Laser Centre",
            city: "Lucknow "
        },
        {
            id: 54,
            name: "Jasmeet  Kaur Marwaha",
            year: "1994",
            House: "Nirmala",
            Profession: "IAD Auditor ",
            organization: "ICICI  Bank ",
            city: "Lucknow "
        },
        {
            id: 55,
            name: "Pranshu mishra",
            year: "1994",
            House: "Nitya",
            Profession: "Journalist- Resident editor",
            organization: "Hindustan Times",
            city: "Lucknow"
        },
        {
            id: 56,
            name: "Gunjan Kapoor Mehrotra ",
            year: "1994",
            House: "Nirmala",
            Profession: "Principal of a school",
            organization: "Skylark World School ",
            city: "Lucknow "
        },
        {
            id: 57,
            name: "Prashant Shukla ",
            year: "1994",
            House: "Nirmala",
            Profession: "Psychiatrist ",
            organization: "Asha Rehabilitation and welfare society ",
            city: "Lucknow "
        },
        {
            id: 58,
            name: "Nillanjana dutta",
            year: "1994",
            House: "Jyoti",
            Profession: "Teacher",
            organization: "Lamartinere Girls college, lko",
            city: "Lucknow"
        },
        {
            id: 59,
            name: "pankaj taneja",
            year: "1995",
            House: "Nirmala",
            Profession: "dentist and director of a coaching institute",
            organization: "matushri dental care , eureka classes ",
            city: "lucknow"
        },
        {
            id: 60,
            name: "DEEPAK M MATHEWS",
            year: "1995",
            House: "Jyoti",
            Profession: "PRINCIPAL ",
            organization: "BHONWAL CONVENT SCHOOL",
            city: "Lucknow"
        },
        {
            id: 61,
            name: "Dinesh Yadav",
            year: "1997",
            House: "Jyoti",
            Profession: "LL.B.",
            organization: "Mortgage and Investment Expert",
            city: "Ayodhya"
        },
        {
            id: 62,
            name: "Apoorva Dixit ",
            year: "1999",
            House: "Jwala ",
            Profession: "Advocate, High Court, Lucknow ",
            organization: "High Court, Lucknow ",
            city: "Lucknow "
        },
        {
            id: 63,
            name: "Narinder jeet singh",
            year: "1999",
            House: "Nirmala",
            Profession: "Bussiness",
            organization: "Kitchen studio, xtreme fitness studio",
            city: "Lucknow"
        },
        {
            id: 64,
            name: "Rishi Mahapatra",
            year: "1999",
            House: "Nitya",
            Profession: "IT Technical Project Manager",
            organization: "Dell Technologies",
            city: "Bangalore"
        },
        {
            id: 65,
            name: "gurdeep singh",
            year: "2000",
            House: "Nirmala",
            Profession: "Regional Head, Retail Risk",
            organization: "CIMB Bank",
            city: "Kuala Lumpur"
        },
        {
            id: 66,
            name: "Gaganpreet Kaur",
            year: "2001",
            House: "Nirmala",
            Profession: "Homemaker",
            organization: "Dashmesh Agency ",
            city: "Lucknow"
        },
        {
            id: 67,
            name: "Kshitij Bal",
            year: "2001",
            House: "Jwala",
            Profession: "Marine engineering, Chief Engineer ",
            organization: "Scorpio Marine India pvt Ltd ",
            city: "Lucknow"
        },
        {
            id: 68,
            name: "Prof. Mohd. Haris Siddiqui",
            year: "2001",
            House: "Kala ",
            Profession: "Registrar and Director",
            organization: "Integral University, Lucknow ",
            city: "Lucknow "
        },
        {
            id: 69,
            name: "Shilpi Bose",
            year: "2001",
            House: "Jwala",
            Profession: "Teacher (Assistant Professor) Department of Commerce",
            organization: "Avadh Girls'Degree College, Lucknow",
            city: "Lucknow"
        },
        {
            id: 70,
            name: " Shri Nishant Kumar Saxena ",
            year: "2002",
            House: "Nirmala",
            Profession: "Advocate ",
            organization: "VARNEYA LAW FIRM",
            city: "Central Delhi"
        },
        {
            id: 71,
            name: "Vishal Rajani",
            year: "2002",
            House: "Nitya House",
            Profession: "Bussiness",
            organization: "M.M.Sons , M.M.Traders- Dcot Alambagh & Sea Rock",
            city: "Lucknow"
        },
        {
            id: 72,
            name: "Priyanka singh",
            year: "2002",
            House: "Nitya",
            Profession: "Orthodontist dentist",
            organization: "Meraki dental clinique, echs army hospital",
            city: "Lucknow"
        },
        {
            id: 73,
            name: "Amit Kumar Sachdeva",
            year: "2002",
            House: "Jwala ",
            Profession: "Owner and Director",
            organization: "Owner - Manas Brick Kiln and Director - Dreamers Playschool",
            city: "Lucknow"
        },
        {
            id: 74,
            name: "Praveen Misra ",
            year: "2002",
            House: "Jyoti",
            Profession: "Proprietor at Uni lubricant industries ",
            organization: "Uni lubricant industries ",
            city: "Lucknow"
        },
        {
            id: 75,
            name: "Mohit Mendiratta",
            year: "2002",
            House: "Nitya",
            Profession: "Senior Manager Capacity Planning",
            organization: "Adobe Systems ",
            city: "Noida"
        },
        {
            id: 76,
            name: "Vikas Dalela",
            year: "2003",
            House: "Jwala",
            Profession: "(Aviation Professional) Manager Sales - UP",
            organization: "IndiGo",
            city: "Lucknow"
        },
        {
            id: 77,
            name: "Megha Sharma ",
            year: "2003",
            House: "Nirmala ",
            Profession: "Business Consulting ",
            organization: "Egon Zehnder ",
            city: "Gurgaon/lucknow "
        },
        {
            id: 78,
            name: "Shweta Singh ",
            year: "2003",
            House: "Jwala",
            Profession: "CEO & founder / Leadership Coach ",
            organization: "Wisdomarchy Pvt Ltd",
            city: "Pune"
        },
        {
            id: 79,
            name: "Anisha budhraja",
            year: "2003",
            House: "Jyoti",
            Profession: "Customer service Senior executive ",
            organization: "Bluedart Express Limited ",
            city: "Lucknow "
        },
        {
            id: 80,
            name: "Dr. Kriti Mishra",
            year: "2003",
            House: "Jwala",
            Profession: "Dentist",
            organization: "None",
            city: "Kanpur"
        },
        {
            id: 81,
            name: "Purva Narang",
            year: "2003",
            House: "Nirmala",
            Profession: "Fitness and Nutrition Coach",
            organization: "Eternal Earth L.L.C-FZ",
            city: "India"
        },
        {
            id: 82,
            name: "Anuja Pandey",
            year: "2003",
            House: "Nitya",
            Profession: "Home maker",
            organization: "Home sweet home 🏡 ",
            city: "Lucknow"
        },
        {
            id: 83,
            name: "Shashank Kotnala",
            year: "2003",
            House: "Nitya",
            Profession: "SBI, Chief Manager",
            organization: "State Bank of India",
            city: "Kanpur"
        },
        {
            id: 84,
            name: "Sujata S Khanal ",
            year: "2003",
            House: "Nirmala ",
            Profession: "Teacher ",
            organization: "Loreto Convent Intermediate College, Lucknow ",
            city: "Lucknow "
        },
        {
            id: 85,
            name: "Sumit Singhal",
            year: "2003",
            House: "Jwala",
            Profession: "Telecom Engineer DGM",
            organization: "Airtel",
            city: "Lucknow"
        },
        {
            id: 86,
            name: "Khyati rajpal",
            year: "2003",
            House: "Jyoti",
            Profession: "Training specialist",
            organization: "Google",
            city: "Lucknow"
        },
        {
            id: 87,
            name: "Nishant Bajpai",
            year: "2004",
            House: "Nirmala",
            Profession: "IT professional",
            organization: "Axa XL",
            city: "Gurugram"
        },
        {
            id: 88,
            name: "Sonal Bhayana ",
            year: "2004",
            House: "Nitya",
            Profession: "Student ",
            organization: "Thapar Institute of Engineering and technology ",
            city: "Patiala "
        },
        {
            id: 89,
            name: "Surjeet Kaur",
            year: "2005",
            House: "Nitya",
            Profession: "Solution Architect(IT)",
            organization: "Schneider Electric",
            city: "Bangalore"
        },
        {
            id: 90,
            name: "Anshu Khanna(Verma)",
            year: "2006",
            House: "Nitya",
            Profession: "Business Online Clases",
            organization: "Anshu Dance Class",
            city: "Lucknow"
        },
        {
            id: 91,
            name: "Priya Rajpal ",
            year: "2006",
            House: "Jyoti",
            Profession: "Choreographer & Graphic Designer ",
            organization: "Freelancer ",
            city: "Lucknow"
        },
        {
            id: 92,
            name: "Ankita Bajpai",
            year: "2006",
            House: "Nirmala",
            Profession: "Senior Manager",
            organization: "Punjab National Bank ",
            city: "Lucknow"
        },
        {
            id: 93,
            name: "Sshakshi Gulati",
            year: "2006",
            House: "Nirmala",
            Profession: "Works in IT as a Sr. Developer Success Specialist",
            organization: "Turing Enterprise",
            city: "Lucknow"
        },
        {
            id: 94,
            name: "Kopal dikshit ",
            year: "2007",
            House: "Nirmala",
            Profession: "Airline professional terminal manager ",
            organization: "Adani",
            city: "Lucknow "
        },
        {
            id: 95,
            name: "Akesh Kathuria",
            year: "2007",
            House: "Nitya",
            Profession: "Business ",
            organization: "Friends marketing ",
            city: "Lucknow "
        },
        {
            id: 96,
            name: "Deepanshu Srivaatava",
            year: "2007",
            House: "Jyoti",
            Profession: "Project Manager",
            organization: "Planafin technologies",
            city: "Noida"
        },
        {
            id: 97,
            name: "Jaspreet Kaur ",
            year: "2007",
            House: "Nirmala",
            Profession: "Teacher ",
            organization: "Seth M.R.Jaipuria School ",
            city: "Lucknow "
        },
        {
            id: 98,
            name: "Bhavya Pandey",
            year: "2008",
            House: "Nirmala",
            Profession: " Consulting, Manager ",
            organization: "Ernst and Young",
            city: "Mumbai"
        },
        {
            id: 99,
            name: "Ojasvi Bhatia",
            year: "2008",
            House: "Nitya",
            Profession: "Head - AR/MR ",
            organization: "Facebook ( Meta )",
            city: "Gurgaon"
        },
        {
            id: 100,
            name: "Shree Shruti Pandey",
            year: "2008",
            House: "Jyoti",
            Profession: "Service , Ch. Office Superintendent",
            organization: "Indian Railways",
            city: "Lucknow"
        },
        {
            id: 101,
            name: "Pragati Rawat",
            year: "2008",
            House: "Nitya",
            Profession: "Wellness Coach ",
            organization: "Health school and body fuel station ",
            city: "Lucknow"
        },
        {
            id: 102,
            name: "Deepika Swaroop ",
            year: "2009",
            House: "Nitya",
            Profession: "Assistant Teacher ",
            organization: "St.Mary’s Convent Inter College ",
            city: "Lucknow "
        },
        {
            id: 103,
            name: "Vishnu Mishra",
            year: "2009",
            House: "Jyoti",
            Profession: "Client Manager",
            organization: "Benefit One Inc.",
            city: "Singapore"
        },
        {
            id: 104,
            name: "Sunny Manglani",
            year: "2009",
            House: "Nitya",
            Profession: "Retail Shop Owner",
            organization: "Namaskar Sarees",
            city: "Lucknow"
        },
        {
            id: 105,
            name: "Swati Awasthi",
            year: "2009",
            House: "Jyoti",
            Profession: "Software Product lead ",
            organization: "Dell",
            city: "London"
        },
        {
            id: 106,
            name: "Ankita Singh",
            year: "2010",
            House: "Nirmala",
            Profession: "Sales, Senior Section officer",
            organization: "AICRA",
            city: "New delhi"
        },
        {
            id: 107,
            name: "Chaitanya Soni ",
            year: "2011",
            House: "Nirmala",
            Profession: "Assistant Manager",
            organization: "Gartner Research & Advisory",
            city: "Gurgaon"
        },
        {
            id: 108,
            name: "Harpreet Kaur Oberoi",
            year: "2011",
            House: "Nirmala",
            Profession: "Associate software specialist",
            organization: "4flow AG",
            city: "Berlin"
        },
        {
            id: 109,
            name: "Etee Kohli",
            year: "2011",
            House: "Jwala",
            Profession: "Audit Manager",
            organization: "Deloitte ",
            city: "Gurgaon "
        },
        {
            id: 110,
            name: "Snigdha Tanwar",
            year: "2011",
            House: "Nirmala ",
            Profession: "Digital Marketing & DataAnalytics.  Design. Sr. Solutions Architect",
            organization: "Adaptive & co. (Ireland) ",
            city: "New Delhi"
        },
        {
            id: 111,
            name: "Preetish Sengupta ",
            year: "2011",
            House: "Nirmala ",
            Profession: "Merchant Navy ",
            organization: "MSC",
            city: "Lucknow"
        },
        {
            id: 112,
            name: "NIKHIL MITTAL",
            year: "2011",
            House: "Jyoti",
            Profession: "Self employed",
            organization: "NM APPRAISAL AND VALUATION CONSULTANTS",
            city: "Lucknow"
        },
        {
            id: 113,
            name: "Dr. MEGHNA ATHWANI ",
            year: "2012",
            House: "NITYA",
            Profession: "Assistant Professor of Statistics ",
            organization: "Shri Jai Narain Misra Post Graduate College, Lucknow ",
            city: "Lucknow "
        },
        {
            id: 114,
            name: "Nikhel Pawah ",
            year: "2012",
            House: "Nitya",
            Profession: "Banker( Assistant Branch Manager)",
            organization: "CSB Bank ",
            city: "Lucknow "
        },
        {
            id: 115,
            name: "Shweta Singh",
            year: "2012",
            House: "Jwala",
            Profession: "Senior Manager  ",
            organization: "ICICI LOMBARD GIC LTD ",
            city: "Lucknow"
        },
        {
            id: 116,
            name: "Preeti Pandey ",
            year: "2014",
            House: "Nitya ",
            Profession: "Wellness Coach ",
            organization: "Health school ",
            city: "Lucknow "
        },
        {
            id: 117,
            name: "KAJAL PUNJABI",
            year: "2015",
            House: "Nirmala",
            Profession: "Teacher",
            organization: "CITY MONTESSORI SCHOOL,LDA",
            city: "LUCKNOW"
        },
        {
            id: 118,
            name: "Divya Verma ",
            year: "2016",
            House: "Nirmala",
            Profession: "Persuing architecture,  student",
            organization: "Accurate Institute Of Architecture And Planning ",
            city: "Delhi"
        },
        {
            id: 119,
            name: "Kabir Gupta",
            year: "2018",
            House: "Nitya",
            Profession: "Business Owner and Hotelier",
            organization: "Venus Electricals Refrigeration and Royal Rooms and Banquet ",
            city: "Lucknow "
        },
        {
            id: 120,
            name: "Kartikeya Malhotra",
            year: "2019",
            House: "Jyoti ",
            Profession: "Business Owner ",
            organization: "Geeta medicos ",
            city: "Lucknow"
        },
        {
            id: 121,
            name: "tarneet kaur sood",
            year: "2022",
            House: "jyoti",
            Profession: "student pursuing BA PSYCHOLOGY",
            organization: "IT COLLEGE",
            city: "lucknow"
        },
        {
            id: 122,
            name: "Suchi Sud",
            year: "10th-1997।   12th -1999",
            House: "Jwala ",
            Profession: "Teacher",
            organization: "Self",
            city: "Mandi"
        },
        {
            id: 123,
            name: "Manjush Mathew ",
            year: "1985 class 7th",
            House: "Nitya",
            Profession: "Business ",
            organization: "Grandezza Jewels and Precious Stones (wholesale) : Ezer Jewellery (Retail)",
            city: "Cochin"
        },
        {
            id: 124,
            name: "Ankita Patwal ",
            year: "2015 ( 10th ) ",
            House: "Jwala",
            Profession: "Software Tester ( Test Engineer ) ",
            organization: "Coforge NIIT Technologies ",
            city: "Lucknow"
        },
        {
            id: 125,
            name: "Sanjay Shukla ",
            year: "NA",
            House: "NA",
            Profession: "Teacher ",
            organization: "City Montessori School ",
            city: "Lucknow"
        }
    ]


export default Alldata
