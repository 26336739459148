import React, { useEffect, useState } from 'react'
import PageHeader from '../Components/PageHeader'
import Gcards from '../Components/Cards/Gcards'
import { useParams } from 'react-router-dom';

const GalleryTwo = () => {
    const { id } = useParams();
    const decodedId = atob(id);
    const [galleryImg, setGalleryImg] = useState([]);

    useEffect(() => {
        getImgbyId();
    }, [])
    const getImgbyId = async () => {
        try {
            const imgID = decodedId;
            const Url = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${Url}/gallery/getgalleryimg/${imgID}`, {
                method: 'GET',
            });
            if (response.status === 200) {
                const result = await response.json();
                setGalleryImg(result);
            }
        } catch (error) {
            console.log(error, "not fecth")
        }
    };
    const gImages = galleryImg?.images;
    return (
        <>
            <section className='history_of_osa'>
                <div className="about_osa_layer">
                    <PageHeader heading="Gallery" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 ">

                                <div className="Gallery_main_container  gallerytwo">
                                    <div className="row">
                                        <h5 className='gallery_heading'>* {galleryImg?.description}</h5>
                                    </div>
                                    <div className="row">
                                        {
                                            gImages?.map((imgs, idx) =>
                                                <Gcards key={idx} img={imgs} />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GalleryTwo
