import React from 'react'
import { Link } from 'react-router-dom/dist'

const NewsCard = (props) => {
    return (
        <>
            <li>
                <Link to={''}>
                    <span className='date_box'>
                        <h5>{props.day}</h5>
                        <p>{props.month}</p>
                    </span>
                    <span className='link_text overflow-hidden'>
                        <h6>{props.nheading}</h6>
                        <p>{props.txt}</p>
                    </span>
                </Link>
            </li>
        </>
    )
}

export default NewsCard
