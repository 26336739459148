export default function ValidationLogin(logindata) {
    const error = {}

    if (!logindata.username) {
        error.username = " Username is Required!";
    }
    if (!logindata.mobile) {
        error.mobile = "Mobile Number is Required";
    }


    return error;
}