import React from 'react'
import PageHeader from '../Components/PageHeader'

const PrincipalMsg = () => {
    return (
        <>
            <section className='history_of_osa'>
                <div className="about_osa_layer">
                    <PageHeader heading="Principal's Message" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about_main_container">
                                    <div className="row">
                                        <div className="col-md-4 text-center">
                                            <div className="card">
                                                <div className="president_img bluebgimg_here ">
                                                    <center><img src="/assets/images/principal/1.png" alt="" />
                                                    </center>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="president_msg">
                                                <h4 className='text-danger'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit, quam.</h4>
                                                <br />
                                                <p><i className="fa fa-quote-left" aria-hidden="true"></i>
                                                    &emsp;Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam vitae accusamus consequatur qui? quis  odio dolorum?</p>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Autem odio voluptatibus beatae maiores, blanditiis voluptas dolores sit. Eius sequi eveniet deleniti laboriosam accusantium iusto nobis cupiditate officiis quis sed quae ut vitae, alias magni iure aperiam ea unde. Error necessitatibus nihil alias ullam reiciendis ea sit? Excepturi molestias atque sit omnis quisquam enim dicta voluptatibus consequatur, eos nihil quis quia deleniti, natus hic itaque explicabo animi repellendus consequuntur distinctio autem sed numquam expedita. Asperiores unde non quisque quos enim recusandae, magni temporibus error aut ab. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit, iure. <i className="fa fa-quote-right" aria-hidden="true"></i>
                                                </p>
                                                <h5 className='text-end President_name'>Sr. Marina C.J.</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrincipalMsg
