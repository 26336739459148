import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

const SidebarMenu = () => {
    const location = useLocation();
    useEffect(() => {
        upper();
    }, [location.pathname]);
    const upper = () => {
        window.scrollTo(0, 0);
    }
    return (
        <>
            <div className="mainmenu fixed-menu">
                <div className="sidebar_menu">
                    <div className="sidebar">

                        <ul className='main_menu_ui'>
                            <div className="menu_txt">
                                <Link to="/" >
                                    <img src="/assets/images/flogo.png" className='sidebar_logo' onClick={upper} alt="" />
                                </Link>
                            </div>
                            {/* <li className='menu_txt'><Link to="/">
                                <img src="/assets/images/flogo.png" className='sidebar_logo' alt="" />
                            </Link> </li> */}
                            <li><Link to="/">
                                <span className='nav_links'>Home</span>
                                <span className='menu_icon'>
                                    <i className='fa fa-home'></i>
                                </span>
                            </Link></li>
                            <li className='submenu_here'>
                                <Link to={''} className='submenulinks'>
                                    <span className='nav_links'>About OSA</span>
                                    <span className='menu_icon'>
                                        <i className="fa-solid fa-circle-info"></i>
                                    </span>
                                </Link>
                                <ul className='sub-menu'>
                                    {/* <Link to="/AboutOsa ">   <li>history of OSA</li></Link> */}
                                    <Link to="/PresidentMsg ">   <li>president's message</li></Link>
                                    <Link to="/VicePresidentmsg ">   <li>vice president's  message</li></Link>
                                    <Link to="/SecretaryMsg ">   <li>secretary's message</li></Link>
                                    <Link to="/Joint_SecretaryMsg ">   <li>Joint secretary's message</li></Link>
                                    <Link to="/OfficeBearers ">   <li>office bearers/Editorial board</li></Link>
                                    {/* <li><Link to="/Principal_Msg">Principal's message</Link></li> */}
                                    <Link to="/Founder_PresidentMsg ">   <li>founder  president's  message</li></Link>
                                    <Link to="/Founder_SecretaryMsg ">   <li>Founder secretary's message</li></Link>
                                    <Link to="/AdvisoryMembers ">   <li>advisory  members</li></Link>
                                </ul>
                            </li>
                            <li><Link to="/Register">
                                <span className='nav_links'>Registration</span>
                                <span className='menu_icon'>
                                    <i className="fa-solid fa-registered"></i>
                                </span>
                            </Link></li>
                            <li><Link to="/OurAchievers">
                                <span className='nav_links'>Our Achievers</span>
                                <span className='menu_icon'>
                                    <i className="fa-solid fa-award"></i>
                                </span>
                            </Link></li>
                            <li><Link to="/AlumniDirectory">
                                <span className='nav_links'>Alumni Directory</span>
                                <span className='menu_icon'>
                                    <i className="fa-solid fa-user-graduate"></i>
                                </span>
                            </Link></li>
                            <li><Link to="/SchoolAnthem">
                                <span className='nav_links'>School Anthem</span>
                                <span className='menu_icon'>
                                    <i className="fa-solid fa-book-open"></i>
                                </span>
                            </Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SidebarMenu
