import React from 'react'
import PageHeader from "../Components/PageHeader"
import Principalcard from '../Components/Cards/Principalcard'


const PrincipalTillNow = () => {
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="Our Principals" />

          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="msg_main_container">
                  <div className="row">
                    <Principalcard name="Sr. Marina C.J." duration="August 2023 - now " pimg="/assets/images/principal/1.png" />
                    <Principalcard name="Sr. Lissy C.J." duration="May 2018 - july 2023" pimg="/assets/images/principal/2.png" />
                    
                    <Principalcard name="Sr. Jyoti C.J." duration="March 2015 - 2018 " pimg="/assets/images/noimgnew.jpg" />
                    <Principalcard name="Sr. Roshni C.J." duration="July 2011 - March 2015" pimg="/assets/images/noimgnew.jpg" />
                    <Principalcard name="Sr. Jane C.J." duration="April 2006 - July 2011 " pimg="/assets/images/noimgnew.jpg" />
                    <Principalcard name="Sr. Elsy C.J." duration="July 2001 - March 2006" pimg="/assets/images/noimgnew.jpg" />
                    
                    <Principalcard name="Sr. Teresina C.J." duration="June 1994 - June 2001 " pimg="/assets/images/noimgnew.jpg" />
                    <Principalcard name="Sr. Vijaya C.J." duration="July 1985 - June 1994" pimg="/assets/images/noimgnew.jpg" />
                    
                    <Principalcard name="Sr. Remegia C.J." duration="February 1983 - July 1985 " pimg="/assets/images/noimgnew.jpg" />
                    
                    <Principalcard name="Sr. Edwina C.J." duration="April 1981 - january 1983" pimg="/assets/images/noimgnew.jpg" />
                    <Principalcard name="Sr. Isabel C.J." duration="January 1978 - March 1981 " pimg="/assets/images/noimgnew.jpg" />

                    <Principalcard name="Sr. Margarita C.J." duration="January 1974 - December 1977 " pimg="/assets/images/noimgnew.jpg" />
                    <Principalcard name="Sr. Genevieve C.J." duration="July 1972 - December 1973" pimg="/assets/images/noimgnew.jpg" />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PrincipalTillNow
