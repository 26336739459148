import React from 'react'
import { Link } from 'react-router-dom';
import { Row, Card, Col, CardBody, CardTitle, CardFooter } from "reactstrap";
import moment from 'moment';
import DOMPurify from 'dompurify';

const BlogCards = (props) => {
    const { result } = props;
    console.log(result, 'res')
    return (
        <>
            {result && result.length > 0 ? (
                result.map((data, idx) => {
                    const unsafeHtmlContent = data.blog_des;
                    const sanitizedHtmlContent = DOMPurify.sanitize(unsafeHtmlContent);
                    const ndate = moment(data.createdAt);
                    const formatteddt = ndate.format('DD-MM-YYYY');
                    const dateParts = formatteddt.split('-');
                    const month = parseInt(dateParts[1], 10) - 1;
                    const day = parseInt(dateParts[0], 10);
                    const year = parseInt(dateParts[2], 10);
                    const myDate = new Date(year, month, day);
                    const formattedDateString = myDate.toLocaleString('en-US', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });
                    const truncateDescription = (text, limit) => {
                        const words = text.split(' ');
                        if (words.length <= limit) {
                            return text;
                        }
                        const truncatedWords = words.slice(0, limit);
                        const truncatedText = truncatedWords.join(' ');

                        return `${truncatedText} ...`;
                    };
                    const truncatedDescription = truncateDescription(sanitizedHtmlContent, 45);
                    const encodedId = btoa(data._id);
                    return (
                        <Col xl={4} lg={4} md={6} sm={6} key={idx} className='blogCards_here'>
                            <Card>
                                <div className="top_blogImg" style={{ height: "220px", background: "#f7f7f7" }}>
                                    {
                                        data.blogimg ? <img className="card-img-top" src={data.blogimg} alt="blog image " /> : <img className="card-img-top" src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDpYgKX6Na9EAfhKgjLD4iyPugeNE0wggdkw&usqp=CAU' />
                                    }
                                </div>

                                <CardBody style={{ height: "230px" }}>
                                    <CardTitle><h5 className='blogtitle_txt'>{data.blog_heading}</h5></CardTitle>
                                    <div className="card-text text-justify fs-5" dangerouslySetInnerHTML={{ __html: truncatedDescription }} onClick={(e) => {
                                        // Ensure links open in a new tab
                                        if (e.target.tagName === 'A') {
                                            e.preventDefault();
                                            window.open(e.target.getAttribute('href'), '_blank');
                                        }
                                    }} />
                                </CardBody>
                                <CardFooter>
                                    <Row>
                                        <Col sm={6} className='text-left'>
                                            <Link to={''}
                                                className="text-muted fw-600 fs-5" >
                                                {formattedDateString}
                                            </Link>
                                        </Col>
                                        <Col sm={6} className='text-end'>
                                            <Link to={`/BlogDetails/${encodedId}`}
                                                className="card-link fs-5 text-primary" >
                                                Read more
                                            </Link>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Col>
                    )
                })
            ) : (
                <div className="col-md-12 text-center">
                    <h3 className='text-danger'>Blog Not Available!</h3>
                </div>
            )
            }
        </>
    )
}

export default BlogCards
