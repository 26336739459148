import React from 'react'
import PageHeader from '../Components/PageHeader'
import OfficeBCards from '../Components/Cards/OfficeBCards'
import Editorialboard from '../Components/Cards/Editorialboard'
const OfficeBearers = () => {
  return (
    <div>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="Office Bearers/Editorial Board" />

          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="officebearersmain_container ">
                  <div className="row">
                    <div className="col-md-12 text-left">
                      <div className="top_heading  text-center">
                        <h4 className='text-danger'>Office Bearers</h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <OfficeBCards name="Prof Mohammad Harris Siddiqui " designation="President" img="/assets/images/Haris_Siddiqui.png" />
                    <OfficeBCards name="Mr. Vikas Dalela" designation="Vice president" img="/assets/images/Vikas Dalela.png" />
                    <OfficeBCards name="Ms. Rumna Banerjee " designation="Secretary" img="/assets/images/Rumna benrji.png" />
                    <OfficeBCards name="Mr. Sunny Mangalani" designation="Joint secretary" img="/assets/images/Mr. Sunny Mangalani_Joint.png" />
                    <OfficeBCards name="Priya Sandhu" designation="Treasurer" img="/assets/images/priya sandhu.png" />
                    <OfficeBCards name="Ms. Sujata S Khanal" designation="Joint Treasurer " img="/assets/images/sujata khanal.png" />
                    <OfficeBCards name="Ms. Anisha Budhraja" designation="Event Manager" img="/assets/images/Ms. Anisha Budhraja.png" />
                    <OfficeBCards name="Ms Deepika Swaroop " designation="Media Manager" img="/assets/images/Ms Deepika Swaroop.png" />
                    <OfficeBCards name=" Ms Amisha Arora " designation="Media Manager" img="/assets/images/Amisha Arora.png" />
                  </div>
                  <br /><br />
                  <div className="row">
                    <div className="col-md-12 text-left">
                      <div className="top_heading text-center">
                        <h4 className='text-danger'>Editorial Board</h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">

                    <Editorialboard name="Sujata s Khanal" img="/assets/images/sujata khanal.png" />
                    <Editorialboard name="Aditi Pandey" img="/assets/images/Aditi Pandey.jpg" />
                    <Editorialboard name="Pragati Mangalani" img="/assets/images/pragati manglani.jpg" />
                    <Editorialboard name="Anisha Budhraja" img="/assets/images/Ms. Anisha Budhraja.png" />
                    <Editorialboard name="Neetu Nakra" img="/assets/images/Neetu Nakra.png" />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default OfficeBearers
