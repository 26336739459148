import React, { useEffect, useState } from 'react'
import PageHeader from '../Components/PageHeader'

const AllNewJoiners = () => {
    const [newsjoiners, setNewjoiners] = useState([]);

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        try {
            const Url = process.env.REACT_APP_BASE_URL;
            const token = localStorage.getItem('authToken');
            const response = await fetch(`${Url}/newjoiners/getImg`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const results = await response.json();
            setNewjoiners([...results].reverse());
            // console.log(results)
        } catch (err) {
            console.log(err, 'images fetching error')
        }
    }
    return (
        <>
            <section className='history_of_osa'>
                <div className="about_osa_layer">
                    <PageHeader heading="New Joiners" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="Gallery_main_container gallerytwo">
                                    <div className="row">
                                        {newsjoiners && newsjoiners.length > 0 ? (
                                            newsjoiners?.map((data, idx) =>
                                                <div className="col-md-3 new_joiner_swiper text-center mt-4">
                                                    <div className="new_joiners_circle" key={idx}>
                                                        <center> <img src={data.imageUrl} alt="" /></center>
                                                        <div className="newjoiner_details">
                                                            <h5>{data.name}</h5>
                                                            <p className='text-center'>{data.batch}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <div className="col-md-12 text-center">
                                                <h3 className='text-danger'>Data Not Available!</h3>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AllNewJoiners
