import React from 'react'
import PageHeader from '../Components/PageHeader'
// Link
const SecretaryMsg = () => {
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="Secretary Message" />


          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="about_main_container">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12 text-center">
                      <div className="card">
                        <div className="president_img bluebgimg_here">
                          <center><img src="/assets/images/Ms Rumna Banerjee_Secretary.png" className="rumnaberji_img" alt="" />
                          </center>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-6 col-12">

                      <div className="president_msg">
                        <div className='secretary_msg'>
                          <h4 className='text-danger text-justify'>Being a part of the old students association fills me with a profound sense of honour and nostalgia. It’s not merely an affiliation; it’s a connection to a shared history, a bond with the institution that shaped our formative years, and a commitment to contributing to its ongoing legacy.</h4>
                        </div>
                        <p><i className="fa fa-quote-left" aria-hidden="true"></i>
                          &emsp;
                          As alumni, we carry the collective memories of our time spent within the hallowed halls of our alma mater. Being a member of the Old Students Association is a testament to the enduring impact of our educational journey and the lasting friendships forged during those years. It’s an acknowledgement of the valuable lessons learnt, the challenges  overcomed, and the growth experienced as individuals.
                          The sense of honour comes not just from the title of being an alumnus but from the realisation that we are now entrusted with the responsibility of being stewards of our alma mater’s traditions and values. It’s a recognition of the duty to pay it forward, support current students, and contribute to the institution’s continued success that has played a pivotal role in shaping our lives.
                          Through the old students association, we have the privilege of reconnecting with former classmates, teachers, and mentors.

                          &emsp;
                          <i className="fa fa-quote-right" aria-hidden="true"></i>
                        </p>

                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <div className="secratary_msg mt-5 pt-4">
                        <p>It is a platform for fostering meaningful connections, sharing experiences, and collectively celebrating the achievements of our fellow alumni.
                          In essence, being a part of this  association is an affirmation of our shared history, a celebration of the diversity of experiences that each alumnus brings, and a commitment to the enduring spirit of our alma mater. It's an honour that transcends time, reminding us of the profound impact of our educational journey and the collective strength that comes from standing together as proud representatives of our alma mater.</p>

                        <h5 className='text-end President_name'>Mrs. Rumna Banerjee</h5>
                        <h5 className=' text-end President_name'>Secretary, OSA-SMCIC</h5>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default SecretaryMsg
