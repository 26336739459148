import React from 'react'


const Principalcard = (props) => {

    return (
        <>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                <div className="card">
                    <img src={props.pimg} className="principal_image" alt="..." />
                    <div className="overlay ">
                        <div className="overlay_text">
                            <h5>{props.name}</h5>
                            <p>{props.duration}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Principalcard
