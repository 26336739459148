import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../Components/PageHeader'
import { Card, Row, Col, Container } from "reactstrap";
import ValidationLogin from "../Validation/LoginForm"

const Login = () => {
  const formRef = useRef();
  const [logindata, setLogindata] = useState({
    username: "",
    mobile: ""
  });
  const [error, setError] = useState({});
  const [records, setRecords] = useState([]);

  const loginonchange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const newdata = { ...logindata, [name]: value }
    console.log(newdata)
    setLogindata(newdata);
  }
  const handlelogin = (e) => {  
    e.preventDefault();
    setError(ValidationLogin(logindata));
    const newrecords = { ...logindata, id: new Date().getTime().toString() }
    setRecords([...records, newrecords]);
    setLogindata({
      username: "",
      mobile: ""
    });
  }
  // const handlelogin = async (e, v) => {
  //   e.preventDefault();
  //   const payload = {
  //     username: v.username,
  //     password: v.password
  //   }
  //   // console.log(payload)
  //   try {
  //     const api_url = process.env.REACT_APP_BASE_URL;
  //     const response = await fetch(`${api_url}/adminusers/login`, {
  //       method: "POST",
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(payload),
  //     });
  //     const results = await response.json();
  //     if (results.status === 1) {
  //       Swal.fire({
  //         title: results.message,
  //         text: 'You are logged in.',
  //         icon: 'success',
  //       });
  //       const token = results.data.token;
  //       localStorage.setItem('authToken', token);
  //       navigate('/');
  //     } else {
  //       Swal.fire({
  //         title: 'Invalid credentials!',
  //         text: results.message,
  //         icon: 'error',
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error, 'error')
  //   }
  // };
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="Login" />
          <Container className="container">
            <Row className="row">
              <Col lg={12} md={12}>
                <div className="registerform_main_container ">
                  <Row>

                    <Col md={3}></Col>
                    <Col md={6}>
                      <Card className="login_form">
                        <form onSubmit={handlelogin} ref={formRef} className='suggestionpage contactform'>
                          <div className="row mt-4 mb-4 ">
                            <div className="col-md-12 text-right">
                              <p>* If you are not registered then <Link to='/Register' className='fw-bold text-primary'>Register</Link></p>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label htmlFor="username" className="form-label">
                              User Name
                            </label>
                            <input type="text" className="form-control" name='username' value={logindata.username} onChange={loginonchange} id="username" placeholder='Enter Your User Name' />
                            {error.username ? <p className='error'>{error.username}</p> : null}

                          </div>
                          <div className="mb-3">
                            <label htmlFor="mobile" className="form-label">
                              Mobile Number
                            </label>
                            <input type="number" className="form-control" name='mobile' value={logindata.mobile} onChange={loginonchange} id="mobile" placeholder='Enter Your Mobile Number' />
                            {error.mobile && <p className='error'>{error.mobile}</p>}

                          </div>
                          <div className="mb-3 form-check">
                            <input type="checkbox" className="form-check-input" checked id="exampleCheck1" />
                            <label className="form-check-label" htmlFor="exampleCheck1">
                              Remember me
                            </label>
                            <p className='forgot_password text-end '><Link to={''}>Forgot Password?</Link></p>
                          </div>
                          <div className="row mt-3 mb-4">
                            <div className="col-md-12 pt-4 text-center">
                              <button type="submit" className="register_btn btn btn-primary "> Login  </button>
                            </div>
                          </div>
                        </form>
                        {/* <AvForm onValidSubmit={handlelogin} >
                          <Row>
                            <Col md={12} className=" text-right">
                              <p>* If you are not registered then <Link to='/Register' className='fw-bold text-primary'>Register</Link></p>
                            </Col>
                            <Col xl={12}>
                              <AvField
                                name="username"
                                type="text"
                                className="form-control form-control-lg"
                                placeholder='Enter Username'
                                label="  Username *"
                                validate={{ required: { value: true } }}
                                errorMessage={` Username cannot be empty`}
                              />
                            </Col>
                            <Col xl={12}>
                              <AvField
                                name="password"
                                type="password"
                                className="form-control form-control-lg"
                                placeholder='Enter Password'
                                label="  Password *"
                                validate={{ required: { value: true } }}
                                errorMessage={`Password cannot be empty`}
                              />
                            </Col>
                            <Col md={12} className='mb-3 text-center mt-5'>
                              <Button type="submit" className="btn btn-primary register_btn">
                                Send <i className="fa fa-paper-plane" aria-hidden="true"></i>
                              </Button>
                            </Col>
                          </Row>
                        </AvForm> */}
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  )
}

export default Login
