import { Modal, ModalHeader, ModalBody } from "reactstrap";
import '../Modals/Modal.css'
import { Link } from 'react-router-dom';

const ModalSection = (props) => {
    const { isOpen, toggle } = props;

    return (
        <>
            <Modal isOpen={isOpen} size="md" toggle={toggle} >
                <ModalHeader toggle={toggle} className='modal_blog_heading' > {props.heading}</ModalHeader>
                <ModalBody className='modal_blog_body'>
                    <h6 className='mb-4'>{props.title}</h6>
                    <p> <i className='fw-500' style={{ fontSize: '17px' }}>{props.topheading}</i> </p>
                    <p> {props.text}</p>
                    <p className='text-end'><b>
                        <Link to={props.link} >{props.linktext}</Link> </b></p>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ModalSection
