import React from 'react'
import PageHeader from '../Components/PageHeader'

const AdvisoryMembers = () => {
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="Advisory Members" />

          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="officebearersmain_container ">
                  <div className="row">
                  <div className="col-md-12 text-center">
                      <h3 className='text-danger'>Coming Soon</h3>

                    </div>
                    {/* <AdvisoryBoard />
                    <AdvisoryBoard />
                    <AdvisoryBoard />
                    <AdvisoryBoard />
                    <AdvisoryBoard />
                    <AdvisoryBoard />
                    <AdvisoryBoard />
                    <AdvisoryBoard /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AdvisoryMembers
