import React from 'react'

const GalleryCard = (props) => {
  return (
    <>
      <div className="card" style={{ width: "100%" }}>
        <div className="card-img">
          <img src={props.img} className="card-img-top" alt="..." />
        </div>
        <div className="card-body">
          <h5 className="card-title">{props.text}</h5>
        </div>
      </div>


    </>
  )
}

export default GalleryCard
