import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './styles.css';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import Skeleton from 'react-loading-skeleton'

const GallerySection = () => {
    const [allImages, setAllimages] = useState([]);

    useEffect(() => {
        fecthImages();
    }, []);
    const fecthImages = async () => {
        try {
            // const token = localStorage.getItem('authToken');
            const apiUrl = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`https://api.osasmciclko.in/gallery/getlast15`);
            const results = await response.json();
            setAllimages([...results].reverse());
            // console.log(results)
        } catch (error) {
            console.log(error, "images not fetch")
        }
    };
    return (
        <>
            <section className='gallerySection bg-light'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-1 col-md-1 col-sm-1 col-1 pl-0 pr-0 footer_blue_line">
                            <div className="blue_line_one"></div>
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-10 col-10 ml-0 pl-0 pr-0">
                            <div className="gallery_section">
                                <div className="gallery_heading text-center">
                                    <h1>Photo Gallery</h1>
                                </div>
                                <div className="gallery_swiper">
                                    <Swiper
                                        grabCursor={true}
                                        spaceBetween={10}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        breakpoints={{
                                            320: {
                                                slidesPerView: 1,
                                            },
                                            480: {
                                                slidesPerView: 2,
                                            },
                                            640: {
                                                slidesPerView: 2,
                                            },
                                            768: {
                                                slidesPerView: 3,
                                            },
                                            1024: {
                                                slidesPerView: 5,
                                            },
                                        }}
                                        rewind={true} autoplay={{
                                            delay: 2900,
                                            disableOnInteraction: false,
                                        }} loop={true} modules={[Navigation, Autoplay, Pagination]} className="mySwiper">
                                        {allImages && allImages.length > 0 ?
                                            (
                                                allImages?.map((Gimg, idx) =>
                                                    <SwiperSlide key={idx}>
                                                        <div className="galler_img">
                                                            <img src={Gimg.images[0]} className='img-fluid' alt="" />
                                                            <div className="gallery_overlay">
                                                                <div className="last_txt">
                                                                    <p>{Gimg.description}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            ) : (
                                                <SwiperSlide >
                                                    <div className="galler_img">
                                                        <Skeleton height={200} />
                                                    </div>
                                                </SwiperSlide>

                                            )
                                        }
                                    </Swiper>

                                    <div className="bottom_content mt-3 text-center">
                                        <Link to="/Gallery" className=''>View All</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-1 col-1 pl-0 pr-0 footer_blue_line">
                            <div className="blue_line_two"></div>

                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="shadow_img footer_cutline">
                    <div className="cut_line">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#d2d2fb" fillOpacity="1" d="M0,160L720,256L1440,128L1440,320L720,320L0,320Z"></path></svg>
                    </div>
                    <div className="cut_line_two">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#9999f7" fillOpacity="1" d="M0,160L720,256L1440,128L1440,320L720,320L0,320Z"></path></svg>
                    </div>
                    <div className="cut_line_three">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#7272f3" fillOpacity="1" d="M0,160L720,256L1440,128L1440,320L720,320L0,320Z"></path></svg>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GallerySection
