import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Modals/Modal.css'
import CardTwo from './Cards/CardTwo'
import CardThree from './Cards/CardThree'
import CardFour from './Cards/CardFour'
import ModalSection from './Modals/ModalSection';

const Featurescard = (props) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    return (
        <>
            <div className="col-lg-3 col-md-6 col-sm-12" onClick={toggle}>
                <div className="card features_box">
                    <Link to={''}>
                        <div className="icon">
                            <center>
                                <img src="assets/images/icon/1.png" alt="" />
                            </center>
                        </div>
                        <div className="bottom_txt">
                            <h5>Job Opportunities</h5>
                        </div>
                    </Link>
                </div>
            </div>
            <CardTwo />
            <CardThree />
            <CardFour />
            <ModalSection isOpen={modal} toggle={() => setModal(!modal)} heading="Job Opportunities"
                title="Dear Marians" text=<span>We have always believed that together we can always
                    overcome any challenges in life. It's time to rise to
                    the occasion and fulfill our duty as a Marian and a friend.
                    So let us embrace the power of connection and cherish the
                    beauty that it unfolds to illuminate the lives of our talented
                    friends by providing job opportunities. <br /> We are thrilled to announce that you can post your requirements for a candidate for recruitment in your firm, office, or business as it's rightly said-
                    A friend in need is a friend indeed.!!!</span>
            />
        </>
    )
}

export default Featurescard
