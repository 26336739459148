import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './styles.css';
import { Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';

const NewJoiners = () => {
    const [newsjoiners, setNewjoiners] = useState([]);

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const Url = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${Url}/newjoiners/getlastTen`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const results = await response.json();
            setNewjoiners([...results].reverse());
            // console.log(results)
        } catch (err) {
            console.log(err, 'images fetching error')
        }
    }
    return (
        <>
            <section className='newjoiners_section'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 text-center">
                            <div className="new_joiners_heading">
                                <h1>New Joiners</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='blue_bgline'>
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                            <div className="new_joiner_swiper">
                                <Swiper
                                    grabCursor={true}
                                    spaceBetween={10}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 1,
                                        },
                                        480: {
                                            slidesPerView: 1,
                                        },
                                        640: {
                                            slidesPerView: 2,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        1024: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                    rewind={true} autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }} loop={true} modules={[Autoplay]} className="mySwiper">
                                    {
                                        newsjoiners?.map((data, idx) =>
                                            <SwiperSlide>
                                                <div className="new_joiners_circle" key={idx}>
                                                    <img src={data.imageUrl} alt="" />
                                                    <div className="newjoiner_details">
                                                        <h5>{data.name}</h5>
                                                        <p className='text-center'>{data.batch}</p>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    }
                                </Swiper>
                                <br />
                                <div className="bottom_content  text-center">
                                    <Link to="/AllNewJoiners" className=''>View All</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewJoiners
