import React, { useState } from 'react'
import PageHeader from '../Components/PageHeader'
import { Link } from 'react-router-dom'
import Validation from '../Validation/ContactForm'
import { toast } from 'react-toastify';

const ContactUs = () => {
  const [formdata, setFormdata] = useState({
    username: "",
    mobile: "",
    email: "",
    Subject: "",
    msg: ""
  });

  const [error, setError] = useState({});
  const [records, setRecords] = useState([]);

  const onchangecontact = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const newdata = { ...formdata, [name]: value };
    setFormdata(newdata);

  }
  const Submitcontactus = async (e) => {
    e.preventDefault();
    try {
      const validationErrors = Validation(formdata);
      if (Object.keys(validationErrors).length > 0) {
        setError(validationErrors);
      } else {
        const payload = {
          name: formdata.username,
          mobile_number: formdata.mobile,
          email: formdata.email,
          Subject: formdata.Subject,
          msg: formdata.msg,
          type: "Contact Us"
        }
        console.log(payload, "PL");
        // return;
        const base_url = process.env.REACT_APP_BASE_URL;
        const response = await fetch(`${base_url}/contacts/insert`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
        if (response.status === 200) {
          toast.success('Data saved successfully!', { autoClose: 2000 })
          console.log(response.statusText, 'Form submitted successfully');
        } else {
          toast.error('Something went wrong!', { autoClose: 2000 })
          console.error('Failed to submit form');
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
    const newrecords = { ...formdata, id: new Date().getTime().toString() }
    setRecords([...records, newrecords])
    setFormdata({ username: "", mobile: "", email: "", Subject: "", msg: "" });
  }
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="Contact Us" />
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="conatactus_main_container">
                  <div className="card">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="contactus_form">
                          <h3>Send Message</h3>

                          <form onSubmit={Submitcontactus} className='suggestionpage contactform'>
                            <div className="mb-3">
                              <label htmlFor="name" className="form-label">
                                Name <span className='text-danger'>*</span>
                              </label>
                              <input type="text" className="form-control" name="username" required value={formdata.username} onChange={onchangecontact} id="name" placeholder='Enter Name' />
                              {/* {error.username && <p className='error'>{error.username}</p>} */}

                            </div>
                            <div className="mb-3">
                              <label htmlFor="mobile" className="form-label">
                                Mobile No.<span className='text-danger'>*</span>
                              </label>
                              <input type="number" className="form-control" name="mobile" required value={formdata.mobile} onChange={onchangecontact} id="mobile" placeholder='Enter Mobile No.' />
                              {/* {error.mobile && <p className='error'>{error.mobile}</p>} */}

                            </div>
                            <div className="mb-3">
                              <label htmlFor="exampleInputEmail1" className="form-label">
                                Email <span className='text-danger'>*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control" name="email" value={formdata.email} onChange={onchangecontact}
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp" required
                                placeholder='Enter Email'
                              />

                              {/* {error.email && <p className='error'>{error.email}</p>} */}

                            </div>

                            <div className="mb-3">
                              <label htmlFor="Subject" className="form-label">
                                Subject <span className='text-danger'>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control" required value={formdata.Subject} onChange={onchangecontact}
                                id="Subject"
                                name='Subject'
                                placeholder='Enter your Address'
                              />
                              {/* {error.Subject && <p className='error'>{error.Subject}</p>} */}

                            </div>
                            <div className="mb-3">
                              <label htmlFor="msg" className="form-label">
                                Message <span className='text-danger'>*</span>
                              </label>
                              <textarea name="msg" id="msg" cols="10" required placeholder='Enter Message Here' className="form-control" value={formdata.msg} onChange={onchangecontact} rows="5"></textarea>
                              {/* {error.msg && <p className='error'>{error.msg}</p>} */}

                            </div>
                            <div className="mb-3 text-center mt-5">
                              <button type="submit" className="register_btn btn btn-primary ">
                                Send <i className="fa fa-paper-plane" aria-hidden="true"></i>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="contactus_form">
                          <h3>Get In Touch</h3>
                          <div className="addresscard">
                            <ul className='get_intouch'>
                              <li><Link to="https://maps.app.goo.gl/dGJh6hG7N4f29qXYA">
                                <i className="fa fa-map-marker" aria-hidden="true"></i> &nbsp; Address
                                <br />
                                <div className='school_address'>
                                  Manak Nagar, RDSO, Lucknow, Uttar Pradesh 226011
                                </div>
                              </Link>
                              </li>
                              <li><Link to="mailto:rdsosmcic@gmail.com">
                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                &nbsp; Email
                                <br />
                                <div className='school_address'>
                                  rdsosmcic@gmail.com
                                </div>
                              </Link>
                              </li>
                              <li><Link to="tel:+91 6388669161">
                                <i className="fa fa-volume-control-phone" aria-hidden="true"></i>
                                &nbsp; Phone
                                <br />
                                <div className='school_address'>
                                  +91 6388669161
                                </div>
                              </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card map_card">
                        <div className="googlr_map_here">
                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3560.5850855126578!2d80.8824127110887!3d26.821336976604616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfe8fccec4517%3A0xd3a801a7d5053f40!2sSt%20Mary&#39;s%20Convent%20Inter%20College!5e0!3m2!1sen!2sin!4v1702279194732!5m2!1sen!2sin" title="google map" width="100%" height="400" style={{ border: '0' }} loading="lazy" ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUs
