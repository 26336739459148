import React, { useState, useEffect } from 'react'
import PageHeader from '../Components/PageHeader'
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton'

const BlogDetails = () => {
    const { id } = useParams();
    const decodedId = atob(id);
    const [blogs, setBlogs] = useState();
    const [loading, setLoading] = useState(true);
    const [des, setDes] = useState();
    const [fDate, setFdate] = useState();

    // console.log(decodedId)
    useEffect(() => {
        getblogs();
    }, []);
    const getblogs = async () => {
        try {
            const token = localStorage.getItem('authToken');
            const api_url = process.env.REACT_APP_BASE_URL;
            const response = await fetch(`${api_url}/blog/getblogbyId/${decodedId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const results = await response.json();
            setBlogs(results);
            const des = results.blog_des;
            const Blog_Description = DOMPurify.sanitize(des);
            setDes(Blog_Description);
            const ndate = moment(results.createdAt);
            const formatteddt = ndate.format('DD-MM-YYYY');
            const dateParts = formatteddt.split('-');
            const month = parseInt(dateParts[1], 10) - 1;
            const day = parseInt(dateParts[0], 10);
            const year = parseInt(dateParts[2], 10);
            const myDate = new Date(year, month, day);
            const formattedDate = myDate.toLocaleString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            });
            setFdate(formattedDate);
            console.log(results)
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <section className='history_of_osa'>
                <div className="about_osa_layer">
                    <PageHeader heading=" Blog Details" />
                    <div className="container mb-0">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="about_main_container ">
                                    <div className="row">
                                        <div className="col-md-1"></div>
                                        <div className="col-md-10 col-sm-12 col-12 ">
                                            <div className="president_msg_oj">
                                                {!loading ? (
                                                    <div className="blogcontent " >
                                                        {blogs.blogimg ?
                                                            <div className="blog_img_here_detailspage">
                                                                <img src={blogs.blogimg} alt="blogimg" />
                                                            </div>
                                                            : ''
                                                        }
                                                        <h4>{blogs.blog_heading}</h4>
                                                        <div className="blog_description pb-5 pt-3 overflow-auto mt-4">
                                                            <div dangerouslySetInnerHTML={{ __html: des }} onClick={(e) => {
                                                                // Ensure links open in a new tab
                                                                if (e.target.tagName === 'A') {
                                                                    e.preventDefault();
                                                                    window.open(e.target.getAttribute('href'), '_blank');
                                                                }
                                                            }} />
                                                            {/* <h5 className='text-end President_name text-danger'> {blogs.author}</h5>
                                                            <h6 className='text-end text-muted fs-5 '>{fDate}</h6> */}
                                                        </div>
                                                        <div className="blog_description  mt-4">
                                                            {/* <div dangerouslySetInnerHTML={{ __html: des }} /> */}
                                                            <h5 className='text-end President_name text-danger'> {blogs.author}</h5>
                                                            <h6 className='text-end text-muted fs-5 '>{fDate}</h6>
                                                        </div>
                                                    </div>

                                                ) : (
                                                    <Skeleton height={400} width={400} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogDetails
