import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../Modals/Modal.css'
import ModalSection from '../Modals/ModalSection';

const CardFour = () => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    return (
        <>
            <div className="col-lg-3 col-md-6 col-sm-12" onClick={toggle}>
                <div className="card features_box">
                    <Link to={''}>
                        <div className="icon">
                            <center>
                                <img src="/assets/images/icon/4.png" alt="" />
                            </center>

                        </div>
                        <div className="bottom_txt">
                            <h5>My Memory</h5>
                        </div>
                    </Link>
                </div>
            </div>
            <ModalSection isOpen={modal} toggle={() => setModal(!modal)}
                heading="My Memory" text=<span>Today together we travel down the memory lane,
                    It ignites our exuberance like propane, Looks like mesmerizing fantasies were once very true.
                    Our "UNIFORM DAYS", the "NOSTALGIC BLUE". <br /> The endurance of our overwhelming teachers,
                    The patience and friendliness of all workers. Under the supervision of our affectionate principal,
                    Who always is the foundation pillar of this institution.  Years passed by from class prep to class 10th/12th,
                    Library books changed from Noddy to Famous Five. <br />From the morning bells, the recession calls,
                    The music and, the energetic sport dribble the balls. The exposition reflects the daily factual competition,
                    The symbolic light brightens the institutional ramification. <br />We learnt to grow together,
                    The innocence, with nothing to bother,
                    Sometimes stubborn, but usually exuberant,
                    Sometimes attention seekers at others defendent.
                    But above all, explorers of our trueselves,
                    Curious, blossoming toddlers to teenagers,
                    Extensive corridors, beautiful gardens created nostalgic foam,
                    Proudly we called the institution our SECOND HOME.</span> />
        </>
    )
}

export default CardFour
