import React, { useEffect,useState } from 'react'
import Slider from '../Components/Slider'
import Featurescard from '../Components/Featurescard'
import NewsSection from '../Components/NewsSection'
import GallerySection from '../Components/GallerySection'
import NewJoiners from '../Components/NewJoiners'
import HomeAbout from '../Components/HomeAbout'
import { Link } from 'react-router-dom'
import Popup from '../Components/Modals/Popup'

const Home = () => {
   
    return (
        <>
            <Popup />
            <div id="main_content" className="main-content">
                <Slider />
                <HomeAbout />
                <section className='after_arrow_content'>
                    <div className="container">
                        <div className="row pl-0 pr-0" >
                            <Featurescard />
                            {/* <Featurescard cardimg="assets/images/icon/1.png" heading="Job Opportunities" txt="Currently  No Jobs available" />
                            <Featurescard cardimg="assets/images/icon/2.png" heading="Invite Friends" txt="Our OSA Members can invite a friends" />
                            <Featurescard cardimg="https://www.accfb.org/wp-content/uploads/2018/06/Blog-icon.png" heading="My Blog" txt="Our OSA Members  can can share blog" />
                            <Featurescard cardimg="assets/images/icon/1.png" heading="My Memory" txt="Our OSA Members can share thier memory" />
                        */}
                        </div>
                    </div>
                </section>

                <section id="about-area" className=" president_msg_section section-padding ">
                    <div className="about-area-wrapper">
                        <div className="container">
                            <div className="row justify-content-end">
                                <div className="col-lg-10">
                                    <div className="about-content-wrap">
                                        <div className="section-title text-lg-left">
                                            <h2> President's  Message </h2>
                                        </div>
                                        <div className="about-thumb">
                                            <img
                                                src="assets/images/misc/about-bg.jpg"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                        <p>
                                            As the President of our cherished Old Students' Association, it brings me great joy to welcome you to our digital hub, a virtual home that celebrates our collective achievements and enduring connections. This platform is a testament to the strength of our alumni network—a space designed for us to reconnect, share stories, and foster the bonds that tie us to our alma mater. Your presence here adds depth to our community and enriches the tapestry of experiences we've woven together.
                                        </p>
                                        <p>
                                            I invite you to explore the array of resources, events and opportunities crafted to nourish our relationships and support your ongoing journey.
                                        </p>
                                        <Link to="/PresidentMsg" className="btn btn-brand about-btn register_btn">
                                            Read more
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <br /><br />
                <NewsSection />
                <NewJoiners />
                <GallerySection />
            </div>
        </>
    )
}

export default Home
