import React from 'react'
import PageHeader from '../Components/PageHeader'

const VicePresidentmsg = () => {
  return (
    <>
      <section className='history_of_osa'>
        <div className="about_osa_layer">
          <PageHeader heading="Vice President's Message" />

          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="about_main_container">
                  <div className="row">
                    <div className="col-md-4 text-center">
                      <div className="card">
                        <div className="president_img bluebgimg_here">
                          <center><img src="/assets/images/Vikas Dalela.png" alt="" />
                          </center>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="president_msg text-center">
                      {/* <p><i className="fa fa-quote-left" aria-hidden="true"></i>
                          &emsp;</p> */}
                         <h5> <br />Coming Soon</h5>
                        {/* <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Autem odio voluptatibus beatae maiores, blanditiis voluptas dolores sit. Eius sequi eveniet deleniti laboriosam accusantium iusto nobis cupiditate officiis quis sed quae ut vitae, alias magni iure aperiam ea unde. Error necessitatibus nihil alias ullam reiciendis ea sit? Excepturi molestias atque sit omnis quisquam enim dicta voluptatibus consequatur,  Iste ducimus numquam beatae sequi odit unde odio dolore ipsum mollitia aperiam. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae corrupti et dignissimos earum ad dolorem quia? Doloribus, voluptatem! Corrupti molestiae tempore doloribus distinctio veniam vel repellendus eos quasi officia, magnam ut possimus, sint blanditiis cupiditate! Blanditiis dolore nobis eligendi. magni temporibus error aut ab. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit, iure. <i className="fa fa-quote-right" aria-hidden="true"></i>
                        </p> */}
                        {/* <h5 className='text-end President_name'>Vikas Dalela</h5> */}
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}

export default VicePresidentmsg
