import React from 'react'

const Editorialboard = (props) => {
    return (
        <>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 editorial_board_card">
                <div className="card" style={{ width: "100%" }}>
                    <img src={props.img} className="card-img-top officebearers_img" alt="..." />
                    <div className="card-body text-center officebearers_txt">
                        <h5 className="card-title">{props.name}</h5>
                        <p className='text-center'>{props.designation}</p>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Editorialboard
